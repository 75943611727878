/* eslint-disable no-nested-ternary */
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

// mui imports
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Skeleton,
  Stack,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

// internal component imports
import { FetchingDisplay } from '../../../components/FetchingDisplay';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { useDataFetcher } from '../../../Hooks/useDataFetcher';
import { feedManagementService } from '../../../services/feedManagement';
import { FilterForm } from './Forms/Filter';
import { PageErrors } from '../../../components/PageErrors';
import {
  useDeleteFilterMutation,
  useEditFilterMutation,
  useGetAffectedItemsQuery,
} from '../../../api/RTK/services/feedFeed';
import { DeleteModal } from './Forms/Components/DeleteModal';
import { TitleCard } from '../Components/GlobalComponents/TitlePaper';
import { DataSendingModalBaseRTK } from '../../../components/DataSendingModalBaseRTK';
import { AffectedItemsButton } from '../Components/GlobalComponents/AffectedItemButton';

// styled component imports
import { StyledPaper } from './StyledMainBox';

const EditFilter = ({ reloadList }) => {
  const { company, user } = useContext(UserInfoContext);

  const location = useLocation();

  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );

  const feedId = useSelector((state) => state.feedSummaryRedux.feedId);

  const memoizedArgs = useMemo(
    () => ({ companyId: company.id, feedProjectId: projectSelectId, feedId }),
    [company, projectSelectId, feedId]
  );

  const { id } = useParams();

  const memoizedFilterArgs = useMemo(
    () => ({
      companyId: company.id,
      feedProjectId: projectSelectId,
      feedId,
      filterId: id,
    }),
    [company, projectSelectId, feedId, id]
  );

  const [state] = useDataFetcher(
    {},
    feedManagementService.getProjectFields,
    memoizedArgs
  );

  const [filterState] = useDataFetcher(
    {},
    feedManagementService.getFilterDetails,
    memoizedFilterArgs
  );

  const [editFilter, { isLoading, isError, isSuccess, reset, error }] =
    useEditFilterMutation();

  const errorHandleEditFilter = () => {
    if (error && error.status === 403) {
      if (error?.data[0]) {
        return error.data[0].error || 'Same name filter exists in other feeds';
      }
    }
    return 'Unable to update filter';
  };

  // Query to get affected items count
  const {
    data: affectedItemsData,
    status: affectedItemStatus,
    isLoading: affecttedItemLoading,
  } = useGetAffectedItemsQuery({
    companyId: company?.id,
    projectId: projectSelectId,
    feedId,
    operationType: 'FILTER',
    operationId: id,
  });
  const handleSubmit = (values) => {
    editFilter({
      projectId: projectSelectId,
      companyId: company.id,
      data: { id, ...values },
    });
  };
  return (
    <FetchingDisplay
      isLoading={state.isLoading || filterState.isLoading}
      isError={state.isError && filterState.isError}
      SuccessElement={
        <ErrorBoundary fallback={<PageErrors />}>
          <Container maxWidth='none'>
            <TitleCard title='Edit Filter'>
              <AffectedItemsButton
                to={{
                  pathname: '../feeds/enhance/affected-items',
                  state: {
                    data: {
                      filtered_out_data:
                        affectedItemsData?.filtered_out_items?.items,
                      remaining_data: affectedItemsData?.remaining_items?.items,
                      tog: 'Removed Items',
                    },
                    path: location.pathname,
                  },
                }}
                affecttedItemLoading={
                  affecttedItemLoading ||
                  isLoading ||
                  (!affectedItemsData && affectedItemStatus !== 'rejected')
                }
                sx={{
                  '&.numberChip': {
                    backgroundColor: '#FF823C !important',
                  },
                  marginRight: '24px',
                }}
                className='animate-border'
                combinedClassName='filterRemoved'
                label={
                  affectedItemsData && affectedItemStatus === 'fulfilled' ? (
                    affectedItemsData?.filtered_out_items?.count.toString()
                  ) : affectedItemStatus === 'pending' ? (
                    <CircularProgress
                      color='inherit'
                      disableShrink
                      sx={{
                        zIndex: 2,
                        width: '16px !important',
                        height: '16px !important',
                      }}
                    />
                  ) : (
                    <ErrorOutlineIcon
                      sx={{
                        color: 'white !important',
                        fontSize: '25px',
                        marginTop: '5px !important',
                      }}
                    />
                  )
                }
                buttonName='View removed items'
              />

              <AffectedItemsButton
                to={{
                  pathname: '../feeds/enhance/affected-items',
                  state: {
                    data: {
                      filtered_out_data:
                        affectedItemsData?.filtered_out_items?.items,
                      remaining_data: affectedItemsData?.remaining_items?.items,
                      tog: 'Remaining Items',
                    },
                    path: location.pathname,
                  },
                }}
                sx={{ marginRight: '24px' }}
                className='animate-border'
                affecttedItemLoading={
                  affecttedItemLoading ||
                  isLoading ||
                  (!affectedItemsData && affectedItemStatus !== 'rejected')
                }
                label={
                  affectedItemsData && affectedItemStatus === 'fulfilled' ? (
                    affectedItemsData?.remaining_items?.count.toString()
                  ) : affectedItemStatus === 'pending' ? (
                    <CircularProgress
                      color='inherit'
                      disableShrink
                      sx={{
                        zIndex: 2,
                        width: '16px !important',
                        height: '16px !important',
                      }}
                    />
                  ) : (
                    <ErrorOutlineIcon
                      sx={{
                        color: 'white !important',
                        fontSize: '25px',
                        marginTop: '5px !important',
                      }}
                    />
                  )
                }
                buttonName='View remaining items'
              />
              {user?.role !== 'COLLABORATOR' &&
                user?.role !== 'SUB_COLLABORATOR' && (
                  <DeleteModal
                    heading='Delete Filter'
                    prompt='Deleting this will permanently remove this filter'
                    successMessage='Filter deleted successfully'
                    errorMessage='Unable to delete Filter'
                    deleteHook={useDeleteFilterMutation}
                    args={{
                      companyId: company.id,
                      projectId: projectSelectId,
                      filterId: filterState.data?.id,
                    }}
                    reloadList={reloadList}
                  />
                )}
            </TitleCard>
            <StyledPaper sx={{ padding: '24px 0px' }}>
              <FilterForm
                fields={state.data?.map((field) => field.name) || []}
                handleSubmit={handleSubmit}
                initialData={filterState?.data}
                btnName='Save'
              />
            </StyledPaper>
          </Container>
          <DataSendingModalBaseRTK
            open={isLoading || isSuccess || isError}
            handleCloseModal={reset}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            successMessage='Filter updated successfully'
            errorMessage={errorHandleEditFilter()}
            reset={reset}
          >
            <Box sx={{ height: '200px' }} />
          </DataSendingModalBaseRTK>
        </ErrorBoundary>
      }
      LoadingElement={
        <Container maxWidth='none'>
          <TitleCard title='Edit Filter'>
            <Button
              variant='pillOutlined'
              sx={{ height: '44px', minWidth: '175px', marginRight: '16px' }}
            >
              <Skeleton animation='wave' width='100%' />
            </Button>
            <Button
              variant='pillOutlined'
              sx={{ height: '44px', minWidth: '175px', marginRight: '16px' }}
            >
              <Skeleton animation='wave' width='100%' />
            </Button>
            <Button
              variant='pillOutlined'
              sx={{ height: '44px', minWidth: '175px' }}
            >
              <Skeleton animation='wave' width='100%' />
            </Button>
          </TitleCard>
          <StyledPaper>
            <Stack
              direction='row'
              alignItems='center'
              gap={3}
              sx={{ paddingBottom: '16px' }}
            >
              <Skeleton
                variant='rectangular'
                animation='wave'
                width='100%'
                height={50}
                sx={{ borderRadius: '8px' }}
              />
              <Skeleton
                variant='rectangular'
                animation='wave'
                width='100%'
                height={50}
                sx={{ borderRadius: '8px' }}
              />
            </Stack>
            <Skeleton
              variant='rectangular'
              animation='wave'
              width='100%'
              height={200}
              sx={{ borderRadius: '8px', marginBottom: '16px' }}
            />
            <Skeleton
              variant='rectangular'
              animation='wave'
              width='100%'
              height={50}
              sx={{ borderRadius: '8px', marginBottom: '16px' }}
            />
          </StyledPaper>
        </Container>
      }
      ErrorElement={
        <PageErrors
          isServerError
          code={state?.errorInfo?.code || filterState?.errorInfo?.code}
        />
      }
    />
  );
};

export { EditFilter };
