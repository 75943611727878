/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const allProjectsRedux = createSlice({
  name: 'PaginationVlues',
  initialState: {
    EnhacepageNumber: 0,
    previewpageNumber: 0,
    affItempageNumber: 0,
  },
  reducers: {
    setEnhancePageNumber(state, action) {
      state.EnhacepageNumber = action.payload;
    },
    setPreviewPageNumber(state, action) {
        state.previewpageNumber = action.payload;
    },
    clearEnhancepageNumber: (state) => {
      state.EnhacepageNumber = 0;
    },
    clearPreviewpageNumber: (state) => {
        state.previewpageNumber = 0;
    },
    clearReduxStore: () => ({
        EnhacepageNumber: 0,
        previewpageNumber: 0,
        affItempageNumber: 0,
    
    }),
  },
});

export const {
  setEnhancePageNumber,
  setPreviewPageNumber,
  clearEnhancepageNumber,
  clearPreviewpageNumber,
  clearReduxStore,
} = allProjectsRedux.actions;

// eslint-disable-next-line import/no-default-export
export default allProjectsRedux.reducer;