/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

// MUI imports
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

// internal imports
import { FetchingDisplay } from '../../../../components/FetchingDisplay';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import { HeadingWithUnderline } from '../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import {
  useDeleteProjectFieldMutation,
  useDeleteFieldByIdMutation,
} from '../../../../api/RTK/services/feedProject';
import { DataSendingModalBaseRTK } from '../../../../components/DataSendingModalBaseRTK';
import { useGetAllFilesQuery } from '../../../../api/RTK/services/feedFile';

const DeleteField = ({ open, setOpen, id, useFieldId }) => {
  const handleCloseModal = () => setOpen(false);
  const { company } = useContext(UserInfoContext);
  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );

  const [deleteField, { isLoading, isSuccess, isError, error, reset }] =
    useDeleteProjectFieldMutation();
  const [
    deleteFieldById,
    {
      isLoading: isLoadingById,
      isSuccess: isSuccessById,
      isError: isErrorById,
      error: errorById,
      reset: resetById,
    },
  ] = useDeleteFieldByIdMutation();
  const { data } = useGetAllFilesQuery(
    {
      companyId: company?.id,
      projectId: projectSelectId,
    },
    { skip: !company }
  );

  useEffect(() => {
    if (!open) {
      reset();
      resetById();
    }
  }, [open, reset, resetById]);
  const handleClick = () => {
    if (useFieldId) {
      deleteFieldById({
        companyId: company.id,
        projectId: projectSelectId,
        fieldId: id,
        fileId: data[0].id,
      });
    } else {
      deleteField({
        companyId: company.id,
        projectId: projectSelectId,
        id,
      });
    }
  };

  return (
    <DataSendingModalBaseRTK
      isLoading={isLoading || isLoadingById}
      isError={
        (isError && error?.status !== 403) ||
        (isErrorById && errorById?.status !== 403)
      }
      isSuccess={isSuccess || isSuccessById}
      open={open}
      handleCloseModal={handleCloseModal}
      successMessage={
        <>
          Deleted{' '}
          <Typography sx={{ color: 'primary.main' }} variant='bigSemiBold'>
            Successfully
          </Typography>
        </>
      }
      errorMessage='Error while deleting field'
      reset={reset || resetById}
    >
      <HeadingWithUnderline heading='Are you sure you want to Delete?' />
      {error && error.status === 403 ? (
        <>
          <Typography
            variant='smallMedium'
            sx={{ color: 'error.main', mb: 1 }}
            component='p'
          >
            <InfoOutlined
              fontSize='1em'
              sx={{ verticalAlign: 'middle', mr: 1 }}
            />
            You can&apos;t delete this file as it was used{' '}
            {Object.values(error?.data?.error).reduce(
              (acc, curr) => acc + curr.length,
              0
            )}{' '}
            times in this project.
          </Typography>
          <Box component='ul' sx={{ listStyle: 'none' }}>
            {Object.keys(error?.data?.error)
              .filter((type) => error?.data?.error[type].length !== 0)
              .map((type) => (
                <li>
                  <Typography variant='smallBold'>{`${_.startCase(
                    type
                  )}s -`}</Typography>
                  <Box component='ul' sx={{ listStyle: 'none' }}>
                    {error?.data?.error[type].map((name) => (
                      <li>
                        <Typography variant='smallRegular'>{name}</Typography>
                      </li>
                    ))}
                  </Box>
                </li>
              ))}
          </Box>
          <Stack direction='row' gap={2}>
            <Button
              onClick={handleCloseModal}
              variant='outlined'
              sx={{ mt: 1 }}
              fullWidth
            >
              Cancel
            </Button>
            <Button
              onClick={handleCloseModal}
              variant='contained'
              sx={{ mt: 1 }}
              fullWidth
              disabled
            >
              Delete
            </Button>
          </Stack>
        </>
      ) : (
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
        >
          {prompt ? (
            <Typography variant='h4' sx={{ mb: 3 }}>
              {prompt}
            </Typography>
          ) : null}
          <Box
            sx={{ width: '100%' }}
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            gap={3}
          >
            <Button variant='outlined' onClick={handleCloseModal} fullWidth>
              Cancel
            </Button>
            <Button variant='contained' onClick={handleClick} fullWidth>
              Delete
            </Button>
          </Box>
        </Box>
      )}
    </DataSendingModalBaseRTK>
  );
};

export { DeleteField };
