import React from 'react';
import { Formik } from 'formik';

// MUI imports
import {
  Box,
  Paper,
  Typography,
  Button,
  Stack,
  FormControl,
  Checkbox,
  IconButton,
  FormGroup,
  FormControlLabel,
  //   Tooltip,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

// Styled imports
import { StyledModal } from '../StyledComponents/StyledCommon';

// Internal component imports
import { DynamicTextField } from '../GlobalComponents/DynamicTextField';

const CustomiseColumnModal = ({
  setCol,
  openCustomCol,
  setCustomCol,
  modalTitle,
}) => { 
  const handleReopenFilterOut = () => {
    setCol(true);
    setCustomCol(false);
  };
  const handleCloseCustomColModal = () => setCol(false);

  return (
    <Formik
      initialValues={{
        newColName: '',
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <StyledModal
            open={openCustomCol}
          
            onClose={handleCloseCustomColModal}
            BackdropProps={{ style: { backdropFilter: 'blur(3px)' } }}
          >
            <Box className='modalBox' component={Paper}>
              <Typography variant='h4' className='modalHeading'>
                {modalTitle}
              </Typography>
              <div style={{ padding: '15px' }}>
                <FormControl sx={{ width: '100%' }}>
                  <DynamicTextField
                    name='newColName'
                    value={values.newColName}
                    textTitle='Name'
                    inputPlaceholder='Example Name'
                    className='newFilterInput'
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormControl>

                <div>
                  <FormGroup
                    sx={{
                      flexDirection: 'row',
                      marginTop: '15px',
                    }}
                  >
                    <FormControlLabel
                      control={<Checkbox />}
                      label='Copy content of existing column'
                      sx={{ marginRight: '0px' }}
                    />

                    <IconButton disableRipple>
                      <HelpOutlineIcon />
                    </IconButton>
                  </FormGroup>
                </div>

                <Stack
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                  sx={{ marginTop: '20px' }}
                >
                  <Button
                    sx={{
                      width: '200px',
                      margin: '5px',
                    }}
                    variant='FilledBtn'
                    onClick={() => setCol(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      width: '200px',
                      margin: '5px',
                    }}
                    variant='FilledBtn'
                    onClick={handleReopenFilterOut}
                    type='submit'
                  >
                    Apply
                  </Button>
                </Stack>
              </div>
            </Box>
          </StyledModal>
        </form>
      )}
    </Formik>
  );
};

export { CustomiseColumnModal };
