import React, { useState, useContext } from 'react';
// MUI imports
import { Backdrop, Box, CircularProgress, Container } from '@mui/material';

// Internal imports
import { useSelector, useDispatch } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { TitleCard } from '../Components/GlobalComponents/TitlePaper';
import { ImportFileForm } from '../Components/Forms/ImportFileForm';
import { ToggleButtonImport } from '../Components/OtherComponents/ToggleButtonImport';
import { DataSendingModalBaseRTK } from '../../../components/DataSendingModalBaseRTK';

// Styled imports
import { StyledPaper } from './StyledMainBox';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { clearInputValue } from '../Redux/ImportForm/slice';
import { useAddFileMutation } from '../../../api/RTK/services/feedFile';
import { useGenerateMappingMutation } from '../../../api/RTK/services/feedMapping';
import { PageErrors } from '../../../components/PageErrors';
import { setFileId, setFileOrder } from '../Redux/ImportedFiles';
import { ProgressLoader } from '../../../widgets/ContentLoaders/ContentLoaders';

const formatter = (values) => ({
  ...values,
  url: values.protocol + values.url.replace(/^(https?:\/\/|sftp:\/\/)/i, ''),
});

const AddFile = () => {
  const [toggled, setToggled] = useState('Settings');
  const fileSelectId = useSelector((state) => state.importedFilesRedux.fileId);
  const { company, accountList } = useContext(UserInfoContext);
  const navigate = useNavigate();

  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );

  const dispatch = useDispatch();

  const clearReduxState = () => {
    dispatch(clearInputValue());
  };

  const persistedData = useSelector((reduxState) => reduxState.importFormRedux);

  const [
    addFile,
    { isLoading: addLoading, isSuccess, isError, error: addErrorInfo, reset },
  ] = useAddFileMutation();
  const [
    generateMapping,
    {
      isLoading: generateLoading,
      isError: generateError,
      error: generateErrorInfo,
      isSuccess: generateSuccess,
      reset: generateReset,
    },
  ] = useGenerateMappingMutation();

  const postFile = (values) => {
    dispatch({ type: 'SENDING' });
    return addFile({
      projectId: projectSelectId,
      companyId: company.id,
      data: formatter(values),
    })
      .then((response) => {
        dispatch(setFileId(response.data.id));
        return Promise.all([
          response,
          generateMapping({
            companyId: company.id,
            projectId: projectSelectId,
            fileId: response.data.id,
          }),
        ]);
      })
      .then(([postResponse, generateResponse]) => {
        if (generateResponse.error) {
          dispatch({
            type: 'SENDERROR',
            error:
              generateResponse.error.data.errors ||
              generateResponse?.error?.data[0]?.error,
          });
        } else {
          dispatch({ type: 'SENDSUCCESS', data: postResponse.data });
          clearReduxState();
          navigate(`../edit-mapping/${postResponse.data.id}`);
          dispatch(setFileOrder(postResponse.data.order));
        }
      })
      .catch((error) => {
        dispatch({ type: 'SENDERROR', error: error.data });
      });
  };

  const errorImport = () => {
    if (isError) {
      return `${
        addErrorInfo?.data?.errors ||
        addErrorInfo?.data[0]?.error ||
        'Unknown error'
      }`;
    }
    if (generateError) {
      return `${
        generateErrorInfo?.data?.errors ||
        generateErrorInfo?.data[0]?.error ||
        'Unknown error'
      }`;
    }
    return 'Unable to create import';
  };

  return (
    <ErrorBoundary fallback={<PageErrors />}>
      <Backdrop
        sx={{ color: '#fff', zIndex: 5 }}
        open={addLoading || generateLoading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <Container maxWidth='xxl'>
        <TitleCard title='Import File'>
          <ToggleButtonImport
            toggled={toggled}
            setToggled={setToggled}
            fileId={fileSelectId}
          />
        </TitleCard>
        <StyledPaper>
          <ImportFileForm
            data={persistedData}
            handleSubmit={postFile}
            accountList={accountList}
          />
        </StyledPaper>
      </Container>
      <DataSendingModalBaseRTK
        open={
          addLoading ||
          isSuccess ||
          isError ||
          generateLoading ||
          generateSuccess ||
          generateError
        }
        handleCloseModal={() => {
          reset();
          generateReset();
          if (generateError) {
            navigate('../update');
          }
        }}
        isLoading={addLoading || generateLoading}
        isSuccess={isSuccess && generateSuccess}
        isError={isError || generateError}
        successMessage='Import successfully created'
        errorMessage={errorImport()}
        isCustom={persistedData?.file_type === 'WOO_COMMERCE'}
        loadingComponent={
          <ProgressLoader
            loading={addLoading}
            success={isSuccess && generateSuccess}
          />
        }
        reset={() => {
          reset();
        }}
      >
        <Box sx={{ height: '200px' }} />
      </DataSendingModalBaseRTK>
    </ErrorBoundary>
  );
};

export { AddFile };
