import React, { useContext, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { object, string, boolean } from 'yup';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import { FormikField } from '../../../../widgets/FormikForm/FormikField';
import { HeadingWithUnderline } from '../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { useEditProjectFieldMutation } from '../../../../api/RTK/services/feedProject';
import { DataSendingModalBaseRTK } from '../../../../components/DataSendingModalBaseRTK';
import { useGetMappingQuery } from '../../../../api/RTK/services/feedMapping';

const validationSchema = object({
    name: string()
        .required('Field name cannot be empty')
        .matches(
            /^[a-z0-9_]+$/,
            'Field name can contain only numbers and lowercase letters. Donot use space or dots in Field name.'
        ),
    data_type: string().required('Type is required'),
    is_list: boolean(),
});

const EditField = ({ field, index, disabled }) => {
    const [open, setOpen] = useState(false);
    const [openIndex, setOpenIndex] = useState(null);
    const handleCloseModal = () => setOpen(false);
    const { company } = useContext(UserInfoContext);
    const projectSelectId = useSelector(
        (State) => State.allProjectsRedux.projectId
    );

    const { fileId } = useParams();

    const { data } = useGetMappingQuery(
        {
            companyId: company?.id,
            projectId: projectSelectId,
            fileId,
        },
        {
            skip: !company || !fileId,
        }
    );

    const [editProjectField, { isSuccess, isLoading, isError, reset }] =
        useEditProjectFieldMutation();

    const customCreatedNames = data.project_fields.filter(call => call.custom === true);

    const handlePatchSubmit = (values) => {
        editProjectField({
            companyId: company.id,
            projectId: projectSelectId,
            data: {
                pkid: customCreatedNames[openIndex]?.pkid,
                name: values?.name,
                data_type: values?.data_type,
                is_list: values?.is_list
            }

        });
    };

    return (
        <>
            <IconButton
                onClick={() => {
                    setOpenIndex(index);
                    setOpen(true);
                }}
                key={field.id}
                sx={{
                    border: '1px solid #dedede',
                    borderRadius: '4px',
                    padding: '4px',
                    '&:hover': { backgroundColor: '#ffffff !important', textDecoration: 'underline' },
                }}
                size='small'
                disableRipple
                disabled={disabled}
            >
                <EditOutlined sx={{ fontSize: '20px' }} />
            </IconButton>
            <DataSendingModalBaseRTK
                open={open}
                handleCloseModal={handleCloseModal}
                isLoading={isLoading}
                isError={isError}
                isSuccess={isSuccess}
                successMessage='Edited field successfully'
                errorMessage='Error while editing the field. Refresh and try again.'
                reset={reset}
            >
                <HeadingWithUnderline heading='Edit Field' />
                <Formik
                    initialValues={{
                        name: customCreatedNames[openIndex]?.name || '',
                        data_type: customCreatedNames[openIndex]?.data_type || '',
                        is_list: customCreatedNames[openIndex]?.is_list || false,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handlePatchSubmit}
                >
                    {({
                        errors,
                        touched,
                        values,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                    }) => (
                        <Form noValidate>
                            <Box
                                display='flex'
                                flexDirection='column'
                                sx={{ mb: 2, FormikField: { label: { marginBottom: '15px' } } }}
                            >
                                <FormikField
                                    name='name'
                                    label={<div style={{ paddingBottom: '5px', textAlign:"left" }}>Field Name</div>}
                                    required
                                    type='text'
                                    placeholder='Enter Name'
                                    value={values.name}
                                    error={Boolean(errors.name) && touched.name}
                                    helperText={touched.name && errors.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={isSubmitting}
                                    fullWidth
                                />
                                <Typography variant='smallMedium' sx={{ paddingTop: '15px' , textAlign:"left" }}>
                                    Type
                                </Typography>
                                <FormControl
                                    error={Boolean(touched.data_type && errors.data_type)}
                                >
                                    <Field as={Select} name='data_type' displayEmpty>
                                        <MenuItem value='' sx={{ color: '#BBB8B8' }}>
                                            <em>Select an item</em>
                                        </MenuItem>
                                        <MenuItem value='TEXT'>Text</MenuItem>
                                        <MenuItem value='NUMBER'>Number</MenuItem>
                                        <MenuItem value='DATETIME'>Date & Time</MenuItem>
                                        <MenuItem value='IMAGE'>Image</MenuItem>
                                        <MenuItem value='GTIN'>GTIN</MenuItem>
                                        <MenuItem value='OBJECT'>Object</MenuItem>
                                        <MenuItem value='BOOLEAN'>Boolean</MenuItem>
                                    </Field>
                                    {touched.data_type && errors.data_type && (
                                        <FormHelperText>{errors.data_type}</FormHelperText>
                                    )}
                                </FormControl>

                                <FormControlLabel
                                    control={<Field as={Checkbox} name='is_list' />}
                                    label={
                                        <Typography variant='smallMedium'>
                                            This field contains multiple items
                                        </Typography>
                                    }
                                    checked={values.is_list}
                                />
                            </Box>
                            <Box
                                sx={{ width: '100%' }}
                                display='flex'
                                flexDirection='row'
                                justifyContent='space-between'
                                gap={3}
                            >
                                <Button
                                    variant='outlined'
                                    onClick={() => setOpen(false)}
                                    fullWidth
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant='contained'
                                    type='submit'
                                    disabled={isLoading}
                                    fullWidth
                                >
                                    Save & Import
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </DataSendingModalBaseRTK>
        </>
    );
};

export { EditField };
