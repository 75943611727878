import { openInstance } from '../api/open.instance';
import { authLogoutURI } from '../constants/endpoints';
import { storageService } from './storage';
import { setAccessToken as setAccessTokenInFile } from './token';

const userLogin = async (dispatch, { accessToken }) => {
  await setAccessTokenInFile(accessToken);
  dispatch({
    type: 'LOGIN',
    payload: {
      isLoggedIn: true,
      accessToken,
    },
  });
};

const removeUserFromState = (dispatch) => {
  // update pendo visitor after a user logs out
  window.pendo.identify({
    visitor: {
      id: 'LOGGED-OUT-USER',
    },
    account: {
      id: 'LOGGED-OUT-USER',
    },
  });
  storageService.setAccountInLocalStorage(null);
  storageService.setCompanyInLocalStorage(null);
  dispatch({
    type: 'LOGOUT',
    payload: {
      isLoggedIn: false,
      user: {
        username: '',
        email: '',
        internalUser: false,
      },
      accountList: null,
      account: null,
      company: null,
      companyList: null,
      companies: [],
      accessToken: '',
      platformCreds: [],
    },
  });
};

const userLogout = (dispatch) => {
  openInstance.post(authLogoutURI).then(() => removeUserFromState(dispatch));
};

const setUser = (dispatch, user) => {
  let dateRange;
  if (user.selected_dashboard_date?.startDate) {
    dateRange = {
      startDate: user.selected_dashboard_date.startDate,
      endDate: user.selected_dashboard_date.endDate,
    };
  } else {
    dateRange = {
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        1
      )
        .toISOString()
        .split('T')[0],
      endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0)
        .toISOString()
        .split('T')[0],
    };
  }
  dispatch({
    type: 'SET_USER',
    payload: {
      isLoggedIn: true,
      user: {
        id: user.id,
        email: user.email,
        username: user.username,
        internalUser: user.internalUser,
        role: user.role,
        projectType: user.projectType,
        projectName: user.projectName,
        projectId: user.projectId,
        company: user.company,
        invitationCount: user.invitationCount,
        selected_dashboard_date: dateRange,
      },
    },
  });
};

const setAccountList = (dispatch, accountList) => {
  dispatch({
    type: 'SET_ACCOUNTLIST',
    payload: {
      accountList,
    },
  });
};

const setAccountListFetchingError = (dispatch) => {
  dispatch({
    type: 'ERROR_WHILE_FETCHING_ACCOUNT_LIST',
  });
};

const setAccount = (dispatch, account) => {
  storageService.setAccountInLocalStorage(account);
  dispatch({
    type: 'SET_ACCOUNT',
    payload: {
      account,
    },
  });
};

const setCompanyList = (dispatch, companyList) => {
  dispatch({
    type: 'SET_COMPANYLIST',
    payload: {
      companyList,
    },
  });
};

const setCompany = (dispatch, company) => {
  storageService.setCompanyInLocalStorage(company);
  storageService.setAccountInLocalStorage(null);
  dispatch({
    type: 'SET_COMPANY',
    payload: {
      company,
    },
  });
};

const setCompanyAndAccount = (dispatch, company, account) => {
  dispatch({
    type: 'SET_COMPANY_AND_ACCOUNT',
    payload: {
      company,
      account,
    },
  });
};

const setAccessToken = (dispatch, accessToken) => {
  dispatch({
    type: 'SET_ACCESS_TOKEN',
    payload: {
      accessToken,
    },
  });
};

export {
  userLogin,
  userLogout,
  setUser,
  setAccountList,
  setAccount,
  setCompanyList,
  setCompany,
  setCompanyAndAccount,
  setAccessToken,
  setAccountListFetchingError,
  removeUserFromState,
};
