import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { Field, useField, useFormikContext } from 'formik';
import React, { useEffect } from 'react';

const JoinOnSelectField = () => {
  const { values, setFieldValue } = useFormikContext();
  const [, meta] = useField('join_on');

  const mappedProjectFields = values.importedFields
    .filter((field) => field.imports)
    .map((f) => f.mapped_to);

  // set join_on to empty string if its existing value does not belong to a selected project field
  useEffect(() => {
    if (
      values.join_on !== '' &&
      !values.importedFields
        .filter((field) => field.imports)
        .map((f) => f.mapped_to)
        .includes(values.join_on)
    ) {
      setFieldValue('join_on', '');
    }
  }, [values.importedFields]);

  return (
    <FormControl
      error={meta.error && meta.touched}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Typography> Merge items on</Typography>
      <Box
        sx={{
          position: 'relative',
          marginLeft: '15px',
          '.MuiInputBase-root': { width: '240px' },
        }}
      >
        <Field as={Select} name='join_on' displayEmpty>
          <MenuItem value=''>
            <em>Select Field to Merge on</em>
          </MenuItem>
          {values.projectFields
            .filter((field) => mappedProjectFields.includes(field.pkid))
            .map((field) => (
              <MenuItem value={field.name} key={field.name}>
                {field.name}
              </MenuItem>
            ))}
        </Field>
        <FormHelperText sx={{ position: 'absolute', bottom: '-20px' }}>
          {meta.touched && meta.error}
        </FormHelperText>
      </Box>
    </FormControl>
  );
};

export { JoinOnSelectField };
