/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useField } from 'formik';
import React, { useContext } from 'react';
import { visuallyHidden } from '@mui/utils';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import { UserInfoContext } from '../../context/UserInfoContext';

const SelectInput = ({
  name,
  optionValueCallback,
  optionContentCallback,
  options,
  label,
  hideLabel = false,
  customOnChange = () => { },
  id,
  sx,
  labelIsAdjacent,
  MenuProps,
}) => {
  const [field, meta] = useField(name);
  const { user } = useContext(UserInfoContext);
  return (
    <FormControl
      fullWidth
      error={meta.error && meta.touched}
      sx={{
        ...(labelIsAdjacent
          ? {
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            alignItems: 'center',
            '& .MuiInputLabel-root': {
              fontSize: '0.875rem',
              fontWeight: 400,
              marginBottom: 0,
            },
          }
          : {}),
        ...sx,
      }}
    >
      <InputLabel
        id={`${id}-label`}
        sx={{
          transform: 'none',
          position: 'static',
          flexShrink: 0,
          mb: 1,
          ...(hideLabel && visuallyHidden),
        }}
      >
        {label}
      </InputLabel>
      {/* 
        Addind this wrapper makes sure the helpertext is not adjacent to the input field when the label is adjacent
      */}
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Select
          labelId={`${id}-label`}
          id={id}
          {...field}
          onChange={(e) => {
            customOnChange(e);
            field.onChange(e);
          }}
          options={options}
          displayEmpty
          disabled={user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR'}
          IconComponent={KeyboardArrowDownRounded}
          sx={{
            backgroundColor: '#FFF',
            height: '45px',
            flex: 1,
            '& .Mui-disabled': {
              backgroundColor: 'transparent'
            }
          }}
          aria-label={label}
          MenuProps={{
            ...MenuProps,
            PaperProps: {
              style: {
                maxHeight: '300px',
              },
            },
          }}
        >
          {options?.map((option) => {
            if (option.subheader) {
              return (
                <ListSubheader>
                  <Typography variant='smallBold' color='#000'>
                    {option.subheader}
                  </Typography>
                </ListSubheader>
              );
            }
            return (
              <MenuItem
                key={optionValueCallback(option)}
                value={optionValueCallback(option)}
              >
                {optionContentCallback(option)}
              </MenuItem>
            );
          })}
        </Select>
        {meta.error && meta.touched && (
          <FormHelperText>{meta.error}</FormHelperText>
        )}
      </Box>
    </FormControl>
  );
};

export { SelectInput };
