import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// MUI imports
import {
  Container,
  Button,
  InputAdornment,
  TextField,
  Stack,
  Box,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
// import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

// Internal imports
import { ErrorBoundary } from 'react-error-boundary';
import { TitleCard } from '../Components/GlobalComponents/TitlePaper';
import { GlobalErrorComponent } from '../../../components/GlobalErrorComponent';
// import { StyledEnhanceTable } from '../Components/StyledComponents/StyledCommon';
import { CustomTable } from '../../../components/Table';
import { AddColumnModal } from '../Components/OtherComponents/AddColumnModal';

// Styled imports
import { StyledPaper } from './StyledMainBox';
import { ToggleAffectedButton } from '../Components/OtherComponents/ToggleAffectedButton';


const TableCellWithEllipsis = ({ value }) => (
  <div
    style={{
      maxWidth: '300px',
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}
  >
    {value}
  </div>
);

const headCells = (data) => {
  if (data?.length > 0) {
    const columns = Object.keys(data[0]).map((key) => ({
      id: key,
      numeric: false,
      label:
        key === 'image_link'
          ? 'Image' // Change label to 'Image' for the first column
          : key
              .split('_')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' '),
      disablePadding: false,
      alignRight: false,
      formatFunction: (row) => {
        if (key === 'image_link') {
          return (
            <img src={row[key]} alt='item_image' height='auto' width='50' />
          );
        }
        return <TableCellWithEllipsis value={row[key]} />;
      },
    }));

    // Move the "Image" column to the beginning
    const imageColumnIndex = columns.findIndex(
      (column) => column.id === 'image_link'
    );
    if (imageColumnIndex > -1) {
      const imageColumn = columns.splice(imageColumnIndex, 1)[0];
      columns.unshift(imageColumn);
    }

    return columns;
  }
  return [];
};

// main function component
const AffectedItemsList = () => {
  const navigate = useNavigate();
  const [openCustomCol, setopenCustomCol] = useState(false);
  const actionId = useSelector(
    (state) => state?.ActionFilterData?.ActionFilterData?.id
  );

  const filterId = useSelector(
    (state) => state?.EditFilteredData?.editedFilterData?.id
  );

  const location = useLocation();

  const handleRedirect = () => {
    if (location?.state?.path.includes('action')) {
      navigate(`../feeds/enhance/action/${actionId}`);
    }
    if (location?.state?.path.includes('filter')) {
      navigate(`../feeds/enhance/filter/${filterId}`);
    }
  };

  const data = location?.state?.data ?? [];
  const [searchQuery, setSearchQuery] = useState('');
  const [toggled, setToggled] = useState(data?.tog);
  // table headcell for action page
  const headCellsTable = headCells(data);

  // table data & headcell for filter page
  const tableDataFiltered = location?.state?.data?.filtered_out_data ?? [];
  const tableDataRemaining = location?.state?.data?.remaining_data ?? [];
  const headCellsTableFiltered = data?.filtered_out_data
    ? headCells(tableDataFiltered)
    : [];
  const headCellsTableRemaining = data?.remaining_data
    ? headCells(tableDataRemaining)
    : [];

  useEffect(() => {
    setToggled(data.tog);
  }, [data.tog]);

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const filteredRows = Array.isArray(data)
    ? data?.filter((row) =>
        Object?.values(row)?.some(
          (value) =>
            typeof value === 'string' &&
            value?.toLowerCase()?.includes(searchQuery)
        )
      )
    : [];

  const remainingDataForSearch = Array.isArray(tableDataRemaining)
    ? tableDataRemaining?.filter((row) =>
        Object?.values(row)?.some(
          (value) =>
            typeof value === 'string' &&
            value?.toLowerCase()?.includes(searchQuery)
        )
      )
    : [];

  const filteredDataForSearch = Array.isArray(tableDataFiltered)
    ? tableDataFiltered?.filter((row) =>
        Object?.values(row)?.some(
          (value) =>
            typeof value === 'string' &&
            value?.toLowerCase()?.includes(searchQuery)
        )
      )
    : [];

  return (
    <>
      <Container maxWidth='xxl'>
        <TitleCard title='Affected Items'>
          <Stack direction='row' justifyContent='space-between'>
            <TextField
              type='search'
              placeholder='Search here'
              value={searchQuery}
              onChange={handleSearch}
              sx={{
                input: {
                  paddingLeft: '5px',
                },
                backgroundColor: '#ffffff',
                borderRadius: '30px',
                width: '200px',
                paddingRight: '16px',
              }}
              InputProps={{
                style: {
                  height: '42px',
                  borderRadius: '30px',
                },
                classes: {
                  root: 'custom-input-root',
                },
                startAdornment: (
                  <InputAdornment position='end'>
                    <SearchIcon sx={{ fontSize: '16px', color: '#999999' }} />
                  </InputAdornment>
                ),
              }}
            />
            {data?.filtered_out_data && data?.remaining_data ? (
              <ToggleAffectedButton toggled={toggled} setToggled={setToggled} />
            ) : null}
            {/*
            <Button
              variant='pillOutlined'
              disableRipple
              sx={{
                height: '44px',
                minWidth: '220px',
                '&:hover': { svg: { color: '#0096ff !important' } },
                svg: { paddingRight: '5px', fontSize: '30px' },
              }}
              onClick={() => setopenCustomCol(true)}
            >
              <EditNoteOutlinedIcon />
              Customise Columns
            </Button> */}
          </Stack>
        </TitleCard>
        <StyledPaper sx={{ padding: '0px 0px 4px 0px' }}>
          <ErrorBoundary fallback={<GlobalErrorComponent />}>
            {data?.filtered_out_data && data?.remaining_data ? (
              <CustomTable
                headCells={
                  toggled === 'Remaining Items'
                    ? headCellsTableRemaining
                    : headCellsTableFiltered
                }
                rowIdField='id'
                rows={
                  toggled === 'Remaining Items'
                    ? remainingDataForSearch
                    : filteredDataForSearch
                }
                verticalBorders={false}
                displayFooter={false}
                // eslint-disable-next-line react/jsx-boolean-value
                enablePagination={true}
                style={
                  // headCellsTableRemaining?.length === 0 ||
                  // remainingDataForSearch?.length === 0 || data?.remaining_data?.length === 0
                  //   ? { height: 'auto' }
                  //   : { height: '1000px' }
                  {height :"auto"}
                }
              />
            ) : (
              <CustomTable
                headCells={headCellsTable}
                rowIdField='id'
                rows={filteredRows}
                verticalBorders={false}
                displayFooter={false}
                enablePagination='true'
                style={
                  filteredRows?.length === 0
                    ? { height: 'auto' }
                    : { height: '1000px' }
                }
              />
            )}
            <Box sx={{ margin: '16px' }}>
              <Button
                sx={{
                  width: '200px',
                  display: 'flex',
                  margin: '25px auto',
                }}
                variant='outlined'
                onClick={handleRedirect}
              >
                Back
              </Button>
            </Box>
          </ErrorBoundary>
        </StyledPaper>
      </Container>

      <AddColumnModal
        openModel={openCustomCol}
        setModelOpen={setopenCustomCol}
        modalTitle='Add or Delete Columns'
        setftype=''
      />
    </>
  );
};

export { AffectedItemsList };
