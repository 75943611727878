/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect, useCallback } from 'react';

import {
  Button,
  Box,
  Typography,
  Stack,
  InputBase,
  Divider,
} from '@mui/material';
import _ from 'lodash';
import SearchIcon from '@mui/icons-material/Search';
import throttle from 'lodash/throttle';
import { CloseButton } from '../../../../../../../widgets/CloseButton';
import { FetchingDataLoader } from '../../../../../../../widgets/Loader/FetchingDataLoader';
import {
  StyledGlobalModal,
  StyledModal,
  StyledNavigationBox,
} from '../utils/StyledPlatformComponents';
import { CheckBoxComponent } from '../utils/CheckBoxComponent';
import { LogoHeadingWithUnderline } from '../../../../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { AccountsService } from '../../../../../../../services/accounts';
import { UserInfoContext } from '../../../../../../../context/UserInfoContext';
import { ErrorMessageBox } from '../utils/ErrorMessageBox';
import { LoadingDialog } from '../../../../../../UserManagement/Components/LoadingDialog';

const Platform = ({
  open,
  handleCloseModal,
  setAssociation,
  getPlatform,
  platformLogo,
  platformHeadingName,
  getOauthUrlResponse,
  getAccountsError,
  errorAccountResponse,
  getAccountsResponse,
  getAccountsLoading,
  getAccountsSuccess,
  getAccountsFetching,
  reset = () => {},
}) => {
  const { company, user, accountList: lists } = useContext(UserInfoContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [accountList, setAccountList] = useState([]);
  const [checkList, setCheckList] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [searched, setSearched] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [accountsError, setAccountsError] = useState(false);

  const handleCheckbox = useCallback(
    (event) => {
      const { checked, value } = event.target;
      setCheckList((prevCheckList) =>
        checked
          ? [...prevCheckList, value]
          : prevCheckList.filter((account) => account !== value)
      );
    },
    [checkList]
  );

  useEffect(() => {
    const filteredResults = accountList?.filter((account) =>
      account.name.toLowerCase().includes(searched.toLowerCase())
    );
    setFilteredAccounts(filteredResults);
  }, [searched, accountList]);

  useEffect(() => {
    setFilteredAccounts(accountList);
  }, [accountList]);
  const handleLink = useCallback(() => {
    const accountsToLink = checkList
      .filter((checkedIndex) => filteredAccounts[checkedIndex] !== undefined)
      .map((checkedIndex) => filteredAccounts[checkedIndex]);

    if (accountsToLink.length > 0) {
      const payload = {
        data: accountsToLink.map((acc) => ({
          ...acc,
          name: acc.name || acc.customer_id,
          platform: getPlatform,
        })),
        company_id: company ? company.id : null,
      };
      // Replace with your AccountsService method for creating accounts
      AccountsService.createAccounts(payload)
        .then((response) => {
          if (response.status === 201) {
            setOpenModal(true);
            setTimeout(() => {
              setOpenModal(false);
              setAssociation((association) => association + 1);
              handleCloseModal();
            }, 2000);
          }
        })
        .catch((err) => {
          if (err.response) {
            setOpenModal(true);
            setAccountsError(true);
            if (err.response.status === 400) {
              setErrorMessage('Something went wrong, please try again.');
            } else {
              setErrorMessage('Something went wrong.');
            }
            setTimeout(() => {
              setOpenModal(false);
              handleCloseModal();
            }, 2000);
          }
        });
    }
  }, [
    checkList,
    filteredAccounts,
    getPlatform,
    company,
    handleCloseModal,
    setAssociation,
    getAccountsResponse,
    getAccountsLoading,
  ]);

  useEffect(() => {
    if (getPlatform) {
      setAccountList([]); // Clear the account list when platform changes to show loading state
      setFilteredAccounts([]);
      setSearched('');
    }
  }, [getPlatform]);

  const HandleClose = () => {
    handleCloseModal();
    reset();
    setSearched('');
  };

  const handleSearchChange = useCallback((event) => {
    setSearched(event.target.value);
  }, []);

  const throttleSearch = throttle((searchValue) => {
    const filteredResults = accountList?.filter((account) =>
      account.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredAccounts(filteredResults);
  }, 100);

  useEffect(() => {
    throttleSearch(searched);
    return () => {
      throttleSearch.cancel();
    };
  }, [searched]);

  const HandleClick = useCallback(() => {
    if (getPlatform) {
      if (errorAccountResponse.status === 406) {
        if (user?.role === 'OWNER') {
          window.open(
            getOauthUrlResponse?.authentication_url,
            '_parent',
            'resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, width=700, height=900, left=500, top=300, titlebar=no, popup=true'
          );
        }
      }
    }
  }, [getPlatform, user, errorAccountResponse]);

  useEffect(() => {
    if (!getAccountsLoading && !getAccountsError && getAccountsSuccess) {
      const sortedAccounts = [...getAccountsResponse?.accounts]?.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setAccountList(sortedAccounts);
    } else {
      setAccountList(getAccountsResponse?.accounts);
    }
  }, [
    getAccountsLoading,
    getAccountsResponse,
    getAccountsError,
    getAccountsSuccess,
  ]);

  return (
    <>
      <StyledModal open={open} onClose={HandleClose} closeAfterTransition>
        <StyledGlobalModal>
          <LogoHeadingWithUnderline
            src={platformLogo}
            heading={`Connect ${platformHeadingName} account`}
          />
          <Typography variant='h5'>
            Accounts linked with -{' '}
            {_.startCase(user.projectName?.toLowerCase() || '')}{' '}
            {_.startCase(user.projectType?.toLowerCase() || '')}
          </Typography>
          {(getAccountsLoading || getAccountsFetching) && (
            <FetchingDataLoader
              message='Fetching your accounts...'
              height='150px'
              isfilterTrue={false}
            />
          )}
          {errorAccountResponse?.status === 406 ? (
            <StyledGlobalModal>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
                gap={2}
                py={3}
              >
                <ErrorMessageBox
                  text={errorAccountResponse?.data?.error?.detail}
                  variant='h3'
                />
                {user?.role === 'OWNER' && (
                  <Button variant='contained' onClick={HandleClick}>
                    Reconnect
                  </Button>
                )}
              </Box>
            </StyledGlobalModal>
          ) : errorAccountResponse?.status !== 406 && getAccountsError ? (
            <ErrorMessageBox
              variant='h3'
              text={errorAccountResponse?.data?.error?.detail || errorMessage}
            />
          ) : null}
          {getAccountsSuccess && (
            <>
              {!getAccountsLoading &&
                !getAccountsFetching &&
                getAccountsResponse?.accounts?.length === 0 && (
                  <ErrorMessageBox
                    variant='h3'
                    text='No accounts available for linking.'
                  />
                )}
              {!getAccountsLoading &&
                !getAccountsFetching &&
                getAccountsResponse?.accounts?.length > 0 && (
                  <>
                    <Box
                      display='flex'
                      justifyContent='flex-start'
                      alignItems='center'
                      sx={{
                        border: '1px solid #DEDEDE',
                        borderRadius: '50px',
                        mt: 2,
                        py: 0.2,
                      }}
                    >
                      <SearchIcon sx={{ mx: 3, color: '#323C46' }} />
                      <InputBase
                        value={searched}
                        onChange={handleSearchChange}
                        placeholder='Search...'
                      />
                    </Box>
                    {searched &&
                      filteredAccounts &&
                      filteredAccounts.length === 0 && (
                        <ErrorMessageBox variant='h3' text='No results found' />
                      )}
                    <Box sx={{ my: 2 }}>
                      <Stack
                        sx={{
                          border: `${
                            searched && filteredAccounts.length === 0
                              ? 'none'
                              : '1px solid #E1E1E1'
                          }`,
                          maxWidth: '600px',
                          maxHeight: '400px',
                          overflowY: 'auto',
                          overflowX: 'hidden',
                        }}
                      >
                        {filteredAccounts?.map((account, index) => (
                          <span key={account.customer_id}>
                            <CheckBoxComponent
                              account={account}
                              index={index}
                              handleCheckbox={handleCheckbox}
                              list={lists}
                            />
                            <Divider />
                          </span>
                        ))}
                      </Stack>
                      {checkList.length === 0 && filteredAccounts.length !== 0 && (
                        <Typography
                          color='error'
                          variant='smallBold'
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            pt: 2,
                          }}
                        >
                          Select at least one account to link
                        </Typography>
                      )}
                    </Box>
                    {filteredAccounts.length !== 0 && (
                      <StyledNavigationBox>
                        <Button
                          variant='contained'
                          onClick={handleLink}
                          fullWidth
                          disabled={checkList.length === 0}
                          disableFocusRipple
                          disableRipple
                        >
                          Link
                        </Button>
                      </StyledNavigationBox>
                    )}
                  </>
                )}
            </>
          )}
          <CloseButton onClick={HandleClose} />
        </StyledGlobalModal>
      </StyledModal>
      <LoadingDialog
        open={openModal}
        isError={accountsError}
        onClose={() => setOpenModal(false)}
        successMessage='Account/Accounts added successfully'
        errorMessage='Error while adding account/accounts'
      />
    </>
  );
};

export { Platform };
