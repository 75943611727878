import React, { useState } from 'react';
import { Button, InputAdornment, TextField } from '@mui/material';
import { SearchOutlined, ClearOutlined } from '@mui/icons-material';

const SearchWithButton = ({ placeholder, showEndAdornment, onClick, disabled }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const handleSearchClick = () => {
    onClick(searchTerm.trim()); // remove white-spaces before sending the request
    setIsSearching(true);
  };

  const handleClearClick = () => {
    setSearchTerm('');
    setIsSearching(false);
    onClick(''); // Clear the search result
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onClick(searchTerm);
    }
  };

  return (
    <TextField
      type='search'
      placeholder={placeholder}
      value={searchTerm}
      onKeyPress={handleKeyPress}
      onChange={(event) => setSearchTerm(event.target.value)}
      onFocus={handleFocus}
      onBlur={handleBlur}
      disabled={disabled}
      InputProps={{
        style: {
          height: '44px',
          borderRadius: '30px',
          width: '100%',
          paddingRight: '8px',
        },
        classes: {
          root: 'custom-input-root',
        },
        clear: false,
        endAdornment: (
          <>
            {isSearching && (
              <InputAdornment position='end'>
                <Button
                  onClick={handleClearClick}
                  
                  disableRipple
                  sx={{
                    minWidth: 'auto',
                    padding: '6px',
                    borderRadius: '30px',
                    backgroundColor: '#dedede',
                    svg: { fontSize: '18px' },
                    '&:hover': { backgroundColor: '#dedede' },
                  }}
                >
                  <ClearOutlined />
                </Button>
              </InputAdornment>
            )}
            {showEndAdornment && (
              <InputAdornment position='end'>
                <Button
                  onClick={handleSearchClick}
                  disableRipple
                  sx={{
                    minWidth: 'auto',
                    padding: '6px',
                    borderRadius: '30px',
                    backgroundColor:
                      isFocused || isSearching ? '#0096ff' : 'inherit',
                    svg: {
                      fontSize: '18px',
                      color:
                        isFocused || isSearching
                          ? '#ffffff !important'
                          : 'inherit',
                    },
                    '&:hover': {
                      backgroundColor:
                        isFocused || isSearching ? '#0096ff' : 'transparent',
                    },
                  }}
                >
                  <SearchOutlined />
                </Button>
              </InputAdornment>
            )}
          </>
        ),
      }}
      sx={{
        '& input[type="search"]::-webkit-search-cancel-button': {
          WebkitAppearance: 'none',
        },
      }}
    />
  );
};

export { SearchWithButton };
