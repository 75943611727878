import React, { useContext, useState } from 'react';


// MUI imports
import { CircularProgress, Container } from '@mui/material';

// Internal imports
import { useSelector } from 'react-redux';
import { useParams , useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { TitleCard } from '../Components/GlobalComponents/TitlePaper';
import { EditMappingTable } from '../Components/OtherComponents/EditMappingTable';
import { ToggleButtonImport } from '../Components/OtherComponents/ToggleButtonImport';
import { ErrorSnackBar } from '../../../widgets/SnackbarNew/ErrorSnackBar/ErrorSnackBar';


// Styled imports
import { StyledPaper } from './StyledMainBox';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { FetchingDisplay } from '../../../components/FetchingDisplay';
import { GlobalLoadingComponent } from '../../../components/GlobalLoadingComponent';
import { useGetMappingQuery , useUpdateMappingMutation} from '../../../api/RTK/services/feedMapping';
import { PageErrors } from '../../../components/PageErrors';

import { formatter  } from '../Components/OtherComponents/EditMappingTable/utils';

// Main function component
const EditMapping = () => {
  const [updateMapping, { isLoading : updateLoading }] = useUpdateMappingMutation();
  const navigate = useNavigate();
  const [snackbarMessage, setSnackbarMessage] = useState('You have unsaved changes');
  const [toggled, setToggled] = useState('Edit Mapping');
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isFormHasError, setIsFormHasError] = useState(false);  
 
  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );
  const { fileId } = useParams();
  const { company } = useContext(UserInfoContext);

  const { data, isLoading, isError, error } = useGetMappingQuery(
    {
      companyId: company?.id,
      projectId: projectSelectId,
      fileId,
    },
    {
      skip: !company || !fileId,
    }
  );
  const [patchValue, setPatchValue] = useState({});  
  const handlePatchClick = async () => {
    try {
      // Example payload for the PATCH request
      const patchData = {
        companyId: company.id,
        projectId: projectSelectId,
        fileId,
        ...formatter(patchValue),
      };
      await updateMapping(patchData).unwrap();
      setSnackbarMessage('Patch request successful');
      navigate('../');
    } catch (err) {
      setSnackbarMessage('Patch request failed');
    }
  };
  const snackbarstyle = {
    backgroundColor: '#fff0e7 !important',
    color: '#FF823C !important',
    minWidth : '500px',
    padding : '10px 20px',
    border:"1px solid #FF823C",
  };
  return (
    <FetchingDisplay
      isLoading={isLoading || updateLoading}
      isError={isError}
      LoadingElement={<GlobalLoadingComponent />}
      ErrorElement={
        <PageErrors
          isServerError
          code={error?.originalStatus || error?.status}
        />
      }
      SuccessElement={
        <ErrorBoundary fallback={<PageErrors />}>
          <Container maxWidth='xxl'>
            {data ? (
              <>
                <TitleCard
                  title='Editing Mapping'
                  subTitle='Only selected fields will be imported'
                >
                  <ToggleButtonImport
                    toggled={toggled}
                    setToggled={setToggled}
                    fileId={fileId}
                  />
                </TitleCard>
                <StyledPaper>
                  <EditMappingTable
                    projectFields={data.project_fields}
                    importedFields={data.imported_fields}
                    setIsFormDirty={setIsFormDirty}
                    setIsFormHasError={setIsFormHasError}
                    setPatchValue={setPatchValue}
                  />
                </StyledPaper>
                {!isFormHasError &&  isFormDirty && (
                  <ErrorSnackBar
                    page='editMapping'
                    message={snackbarMessage}
                    autoHideDuration={null}
                    onPatchClick={handlePatchClick}
                    sx={snackbarstyle}
                  />
                )}
              </>
            ) : (
              <CircularProgress />
            )}
          </Container>
        </ErrorBoundary>
      }
    />
  );
};

export { EditMapping };
