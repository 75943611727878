import React, { useContext, useMemo, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// MUI imports
import { CircularProgress, Container, Stack } from '@mui/material';
import { useSelector } from 'react-redux';

// Internal imports
import { TitleCard } from '../Components/GlobalComponents/TitlePaper';
import { FeedsQualityTable } from '../Components/OtherComponents/FeedsQualityTable';
import { ToggleButtonFeeds } from '../Components/OtherComponents/ToggleButtonFeeds';
import { useDataFetcher } from '../../../Hooks/useDataFetcher';

// Styled imports
import { StyledPaper } from './StyledMainBox';
import { feedManagementService } from '../../../services/feedManagement';
import { UserInfoContext } from '../../../context/UserInfoContext';

const FeedsQuality = () => {
  const [toggled, setToggled] = useState('Quality');
  const [loadCount, setLoadCount] = useState(0);
  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );

  const feedSelectId = useSelector((state) => state.feedSummaryRedux.feedId);

  const { company } = useContext(UserInfoContext);

  const memoizedArgs = useMemo(
    () => ({
      companyId: company.id,
      projectId: projectSelectId,
      feedId: feedSelectId,
    }),
    [company, projectSelectId, feedSelectId]
  );

  const reloadList = () => setLoadCount(loadCount + 1);

  const [state] = useDataFetcher(
    {},
    feedManagementService.getQualityForm,
    memoizedArgs,
    loadCount
  );

  // Patch request from snackbar

  return (
    <Container maxWidth='xxl'>
      {state.data ? (
        <>
          <TitleCard title='Quality'>
            <ToggleButtonFeeds toggled={toggled} setToggled={setToggled} />
          </TitleCard>
          <StyledPaper sx={{ padding: '16px 44px' }}>
            <FeedsQualityTable
              explanation='Try to map all Mandatory fields as they make up for 70% of
                your total Feed Quality. Recommended and Optional fields
                make up 20% and 10% of your Feed Quality respectively'
              reloader={reloadList}
              channelFields={state.data.channel_fields}
              internalFields={state.data.project_fields}
              channelInfo={state.data.channel_type}
              marketType={state.data.market_type}
            />
          </StyledPaper>
        </>
      ) : (
        <Stack justifyContent='center' alignItems='center'>
          <CircularProgress sx={{ margin: '100px auto' }} />
        </Stack>
      )}
    </Container>
  );
};

export { FeedsQuality };
