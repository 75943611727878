import { useEffect } from 'react';
import { AccountsService } from '../services/accounts';
import { checkAuth, fetchUserInfo } from '../services/auth';
import { DashboardService } from '../services/dashboard';
import { storageService } from '../utils/storage';
import {
  setAccessToken as setAccessTokenInFile,
  getAccessToken as getAccessTokenFromFile,
} from '../utils/token';

import {
  setAccountList,
  setAccountListFetchingError,
  setCompany,
  setCompanyAndAccount,
  setCompanyList,
  setUser,
  userLogin,
  userLogout,
} from '../utils/userInfoDispatchFunctions';

const useLogin = (
  { isLoggedIn, company, companyList, user, accountList },
  userInfoDispatch,
  fetchingInfo,
  fetchingInfoDispatch
) => {
  useEffect(() => {
    // this useEffect is run on each dependency change
    // but the promiseChain is invoked only when isLoggedIn is false
    // It is set to true in the first then block.
    // Due to this the promise chain is not called even though the useEffect is called again
    if (isLoggedIn) {
      return; // this makes sure the promise chain below is called only once
    }
    fetchingInfoDispatch({ type: 'loading' });
    checkAuth()
      .then((data) => {
        const { accessToken: responseAccessToken } = data;
        setAccessTokenInFile(responseAccessToken);
        userLogin(userInfoDispatch, data, responseAccessToken);
      })
      .catch(() => {
        // initialize pendo when a non-loggedin user loads the app
        window.pendo.initialize({
          visitor: {
            id: 'VISITOR-UNIQUE-ID',
          },
        });
        if (isLoggedIn) {
          userLogout(userInfoDispatch);
        }
        fetchingInfoDispatch({ type: 'error' });
      });
    // eslint-disable-next-line no-unused-vars
  }, [isLoggedIn, fetchingInfoDispatch, userInfoDispatch]);

  // fetches companies after accessToken is set
  useEffect(() => {
    if (isLoggedIn && getAccessTokenFromFile() && !companyList) {
      fetchingInfoDispatch({ type: 'loading' });
      fetchUserInfo(true)
        .then((userInfo) => {
          const fetchedCompanyList =
            DashboardService.getCompanyListWithoutMetrics();
          return Promise.all([userInfo, fetchedCompanyList]);
        })
        .then(([userInfo, fetchedCompanyList]) => {
          // user is set here
          setUser(userInfoDispatch, userInfo);
          setCompanyList(
            userInfoDispatch,
            fetchedCompanyList.data ? fetchedCompanyList.data : []
          );
          if (userInfo.project_type === 'COMPANY') {
            setCompany(userInfoDispatch, fetchedCompanyList.data[0]);
          }
        })
        .catch(() => {
          if (isLoggedIn) {
            userLogout(userInfoDispatch);
          }
          fetchingInfoDispatch({ type: 'error' });
        });
    }
  }, [isLoggedIn, companyList, fetchingInfoDispatch, userInfoDispatch]);

  useEffect(() => {
    if (companyList) {
      window.pendo.initialize({
        visitor: {
          id: user.id,
          email: user.email,
        },
        account: {
          id: user.id,
        },
      });
      // get account and company from localstorage
      const localCompany = storageService.getCompanyFromLocalStorage();
      const localAccount = storageService.getAccountFromLocalStorage();
      // if company does not exist in local storage
      if ((!localCompany && !localAccount) || (!localCompany && localAccount)) {
        storageService.setAccountInLocalStorage(null);
        fetchingInfoDispatch({ type: 'success' });
      } else {
        // company exists in local storage

        // fetch accounts of the local company
        AccountsService.getAccounts(localCompany.id)
          .then((response) => {
            if (
              localAccount &&
              response.data.map((acc) => acc.id).includes(localAccount?.id)
            ) {
              // valid company and valid account
              // set both company and account
              setCompanyAndAccount(
                userInfoDispatch,
                localCompany,
                localAccount
              );
            } else {
              // valid company with invalid account or non existing account
              // set only company
              setCompany(userInfoDispatch, localCompany);
            }
            setAccountList(userInfoDispatch, response.data);
          })
          .catch(() => {
            // invalid company
            storageService.setAccountInLocalStorage(null);
            storageService.setCompanyInLocalStorage(null);
            fetchingInfoDispatch({ type: 'success' });
          });
      }
    }
  }, [
    companyList,
    userInfoDispatch,
    fetchingInfoDispatch,
    user.id,
    user.email,
  ]);

  useEffect(() => {
    if (accountList) {
      fetchingInfoDispatch({ type: 'success' });
    }
  }, [accountList, fetchingInfoDispatch]);

  useEffect(() => {
    if (company) {
      AccountsService.getAccounts(company.id)
        .then((response) => setAccountList(userInfoDispatch, response.data))
        .catch(() => {
          setAccountListFetchingError(userInfoDispatch);
        });
    }
  }, [company]);
};

export { useLogin };
