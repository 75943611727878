/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';

// MUI imports
import {
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Paper,
  Typography,
  Button,
  Stack,
  Tooltip,
  ListItem,
  List,
  CircularProgress,
  Backdrop,
  Skeleton,
} from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import { DragIndicatorOutlined } from '@mui/icons-material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AddIcon from '@mui/icons-material/Add';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

// internal imports
import { UserInfoContext } from '../../../../context/UserInfoContext';
import { FetchingDisplay } from '../../../../components/FetchingDisplay';
import { ImportModal } from './ImportActionModal';
import {
  useEditActionsMutation,
  useEnhanceFeedMutation,
  useGetActionsQuery,
  useGetProjectImportActionsQuery,
} from '../../../../api/RTK/services/feedFeed';
import { ReactComponent as EmptyBox } from '../../../../assests/images/artWork/EmptyBox.svg';

// Styled imports
import { StyledFAModal } from '../StyledComponents/StyledCommon';
import { useItemsOverviewQuery } from '../../../../api/RTK/services/feedItems';


// main function component
const ActionMainModal = ({ modalTitle }) => {
  const [open, setOpen] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [importData, setImportData] = useState([]);
  const [fetchActions, setFetchActions] = useState(false);
  const [fetchProjectImportActions, setFetchProjectImportActions] = useState(false);
  const [importCompleted, setImportCompleted] = useState(false);
  const { company, user } = useContext(UserInfoContext);

  // fetching project id & feed id stored in redux store
  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );
  const feedId = useSelector((state) => state?.feedSummaryRedux?.feedId);

  const { data, isLoading, isError } = useGetActionsQuery({
    companyId: company?.id,
    projectId: projectSelectId,
    feedId,
  }, {
    skip: !fetchActions && !importCompleted
  });

  const { data: projectActions } = useGetProjectImportActionsQuery({
    companyId: company?.id,
    projectId: projectSelectId,
  }, {
    skip: !fetchProjectImportActions
  });

  // use rtk hooks to reload enhance items request automatically
  const [editActions, { isLoading: editLoading }] = useEditActionsMutation();
  const [enhanceFeed, { isLoading: enhanceLoading }] = useEnhanceFeedMutation();

  const { isLoading: overviewLoading, refetch } = useItemsOverviewQuery({
    companyId: company?.id,
    projectId: projectSelectId,
    feedId,
  });

  const handleOpenActions = () => {
    setOpen(true);
    setFetchActions(true);
    setFetchProjectImportActions(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFetchActions(false);
    setFetchProjectImportActions(true);
  };

  const handleImportClose = () => {
    setOpenImportModal(false);
    setOpen(true);
    setImportCompleted(true);
  };

  const handleSubmit = (values) => {
    editActions({
      companyId: company?.id,
      projectId: projectSelectId,
      actions: values?.actions?.map((action, index) => ({
        ...action,
        order: index,
      })),
    })
      .then(() =>
        enhanceFeed({
          companyId: company?.id,
          projectId: projectSelectId,
          feedId,
        })
      )
      .then(() => {
        refetch();
        handleClose();
      });
  };


  const lastOrderNumber = data?.reduce((maxOrder, action) => action.order > maxOrder ? action.order : maxOrder, 0);


  return (
    <>
      <Button
        sx={{
          borderRadius: '30px',
          border: '1px solid #dedede',
          color: '#323c46',
          height: '44px',
          minWidth: '110px',
          '&:hover': {
            svg: { color: '#0096ff !important' },
            color: '#0096ff',
            backgroundColor: 'transparent',
            border: '1px solid #0096ff',
          },
          '@media (max-width: 767px)': {
            width: '100%',
          },
        }}
        onClick={() => {
          handleOpenActions();
        }}
      >
        <AdsClickIcon sx={{ marginRight: '4px' }} />
        Actions
      </Button>
      <StyledFAModal
        open={open}
        onClose={handleClose}
        BackdropProps={{ style: { backdropFilter: 'blur(3px)' } }}
      >
        <Box className='modalBox' component={Paper}>
          {/* backdrop prevents re-ordering items while waiting for response */}
          <Backdrop
            sx={{ color: '#fff', borderRadius: 1, zIndex: 1 }}
            open={editLoading || enhanceLoading || overviewLoading}
            onClick={handleClose}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
          <Typography variant='h4' className='modalHeading'>
            {modalTitle}
          </Typography>

          <FetchingDisplay
            isLoading={isLoading}
            isError={isError}
            SuccessElement={
              // position and zIndex make sure backdrop stays above the action tab items
              <FormGroup
                sx={{ padding: '20px', position: 'relative', zIndex: 0 }}
              >
                {data && data.length > 0 ? (
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Typography variant='smallBold'>
                      Select actions to perform
                    </Typography>
                    <Typography variant='smallRegularFaded'>
                      Drag to set order
                    </Typography>
                  </Stack>
                ) : (
                  <>
                    <Stack
                      direction='column'
                      justifyContent='space-between'
                      alignItems='center'
                      spacing={2}
                      sx={{
                        'svg': {
                          height: '60px !important'
                        }
                      }}
                    >
                      <Typography variant='smallBold'>
                        No actions created yet
                      </Typography>
                      <EmptyBox />
                    </Stack>
                    <Stack
                      direction='row'
                      justifyContent='space-between'
                      sx={{
                        a: {
                          justifyContent: 'flex-start',
                          padding: '8px 0px',
                          color: '#0096ff',
                          alignItems: 'center',
                          textDecoration: 'none',
                          fontWeight: '500 !important',
                          '&:hover': {
                            textDecoration: 'underline',
                            color: '#0096ff',
                          },
                        },
                        marginTop: '16px',
                      }}
                    >
                      <Link
                        as={Button}
                        variant='text'
                        disableRipple
                        to='../feeds/enhance/new-action'
                      >
                        + Create new action
                      </Link>
                      <Button
                        variant='text'

                        disableRipple
                        sx={{
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          padding: '6px 0px',
                          '& svg': {
                            color: '#0096ff !important'
                          },
                          '&:hover': {
                            textDecoration: 'underline',
                            color: '#0096ff',
                            backgroundColor: 'transparent',
                          },
                        }}
                        onClick={() => {
                          setOpenImportModal(true);
                          handleClose();
                          setImportData(projectActions);
                        }}
                      >
                        <FileDownloadOutlinedIcon sx={{ fontSize: '18px' }} />
                        <Typography>Import action</Typography>
                      </Button>

                    </Stack>
                  </>
                )}
                <Formik
                  initialValues={{ actions: data || [] }}
                  enableReinitialize
                  onSubmit={handleSubmit}
                >
                  {({ values, setValues, isSubmitting }) => {
                    const handleDragEnd = (result) => {
                      if (!result.destination) {
                        return; // dropped outside the list
                      }
                      const itemsCopy = [...values.actions];
                      const [reorderedItem] = itemsCopy.splice(
                        result.source.index,
                        1
                      );
                      itemsCopy.splice(
                        result.destination.index,
                        0,
                        reorderedItem
                      );

                      setValues({ actions: itemsCopy });
                    };

                    return (
                      <Form>
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <Droppable
                            droppableId='droppable'
                            renderClone={(provided, _, rubric) => (
                              <div
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                <Stack
                                  direction='row'
                                  alignItems='center'
                                  justifyContent='space-between'
                                  sx={{
                                    border: '1px solid #dedede',
                                    borderRadius: '4px',
                                    backgroundColor: '#ffffff',
                                    padding: '5px 10px',
                                    width: '100%',
                                  }}
                                >
                                  <Stack direction='row' alignItems='center'>
                                    <DragIndicatorOutlined
                                      sx={{
                                        color: '#dedede',
                                        marginRight: '4px',
                                        fontSize: '20px',
                                      }}
                                    />
                                    <Field
                                      type='checkbox'
                                      as={FormControlLabel}
                                      name={`actions[${rubric.source.index}].active`}
                                      control={<Checkbox size='small' />}
                                      label={
                                        values.actions[rubric.source.index].name
                                      }
                                      disabled={isSubmitting}
                                    />
                                    <Tooltip
                                      title={
                                        values.actions[rubric.source.index]
                                          .description
                                      }
                                      arrow
                                      PopperProps={{
                                        sx: {
                                          '&.MuiTooltip-popper ': {
                                            zIndex: '999999999',
                                          },
                                        },
                                      }}
                                    >
                                      <HelpOutlineOutlinedIcon
                                        fontSize='small'
                                        color='disabled'
                                        sx={{ marginLeft: '-4px' }}
                                      />
                                    </Tooltip>
                                  </Stack>
                                  <Button
                                    sx={{
                                      minWidth: 'auto',
                                      border: '1px solid #dedede',
                                      borderRadius: '4px',
                                      padding: '2px',
                                      backgroundColor: '#ffffff',
                                    }}
                                  >
                                    <EditOutlinedIcon
                                      fontSize='small'
                                      color='disabled'
                                    />
                                  </Button>
                                </Stack>
                              </div>
                            )}
                          >
                            {(provided) => (
                              <List
                                className='filtersList'
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                  listStyleType: 'none',
                                  width: '100%',
                                  zIndex: '999999999999999999',
                                  maxHeight: '300px',
                                  overflow: 'auto',
                                }}
                              >
                                {values.actions.map((action, index) => (
                                  <Draggable
                                    key={action.order}
                                    draggableId={action.order ? action.order?.toString() : `${lastOrderNumber + index + 1}`}
                                    index={index}
                                    isDragDisabled={(user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR')}
                                    portal={document.body}
                                  >
                                    {(providedInner, snapshot) => (
                                      <ListItem
                                        ref={providedInner.innerRef}
                                        {...providedInner.draggableProps}
                                        {...providedInner.dragHandleProps}
                                        style={{
                                          ...providedInner.draggableProps.style,
                                          zIndex: snapshot.isDragging
                                            ? 9999999999999
                                            : 'auto',
                                          width: '100%',
                                          padding: '0px',
                                          margin: '8px auto',
                                        }}
                                      >
                                        <Stack
                                          direction='row'
                                          alignItems='center'
                                          justifyContent='space-between'
                                          sx={{
                                            border: '1px solid #dedede',
                                            borderRadius: '4px',
                                            backgroundColor: '#ffffff',
                                            padding: '2px 10px',
                                            width: '100%',
                                          }}
                                        >
                                          <Stack
                                            direction='row'
                                            alignItems='center'
                                          >
                                            <DragIndicatorOutlined
                                              sx={{
                                                color: '#dedede',
                                                marginRight: '4px',
                                                fontSize: '20px',
                                              }}
                                            />
                                            <Field
                                              type='checkbox'
                                              as={FormControlLabel}
                                              name={`actions[${index}].active`}
                                              control={
                                                <Checkbox size='small' />
                                              }
                                              label={action.name}
                                              disabled={isSubmitting || (user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR')}
                                            />
                                            <Tooltip
                                              title={action.description}
                                              arrow
                                              PopperProps={{
                                                sx: {
                                                  '&.MuiTooltip-popper ': {
                                                    zIndex: '999999999',
                                                  },
                                                },
                                              }}
                                            >
                                              <HelpOutlineOutlinedIcon
                                                fontSize='small'
                                                color='disabled'
                                              />
                                            </Tooltip>
                                          </Stack>
                                          <Button
                                            component={Link}
                                            to={`../feeds/enhance/action/${action.id}`}
                                            sx={{
                                              minWidth: 'auto',
                                              border: '1px solid #dedede',
                                              borderRadius: '4px',
                                              padding: '2px',
                                              backgroundColor: '#ffffff',
                                            }}
                                          >
                                            {(user?.role !== 'COLLABORATOR' && user?.role !== 'SUB_COLLABORATOR') ?
                                              (
                                                <EditOutlinedIcon
                                                  fontSize='small'
                                                  color='disabled'
                                                />
                                              ) : (
                                                <VisibilityOutlinedIcon
                                                  fontSize='small'
                                                  color='disabled'
                                                />
                                              )}
                                          </Button>
                                        </Stack>
                                      </ListItem>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </List>
                            )}
                          </Droppable>
                        </DragDropContext>

                        {values.actions.length > 0 && (
                          (user?.role !== 'COLLABORATOR' && user?.role !== 'SUB_COLLABORATOR') && (
                            <Stack
                              direction='row'
                              justifyContent='space-between'
                              alignItems='center'
                              sx={{
                                a: {
                                  justifyContent: 'flex-start',
                                  padding: '8px 0px',
                                  color: '#0096ff',
                                  display: 'flex',
                                  alignItems: 'center',
                                  textDecoration: 'none',
                                  fontWeight: '500 !important',
                                  '&:hover': {
                                    textDecoration: 'underline',
                                    color: '#0096ff',
                                  },
                                },
                              }}
                            >
                              {values.actions?.length > 0 && (
                                <Link
                                  as={Button}
                                  variant='text'
                                  disableRipple
                                  sx={{
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    padding: '6px 0px',
                                  }}
                                  to='../feeds/enhance/new-action'
                                  disabled={isSubmitting}
                                >
                                  <AddIcon sx={{ fontSize: '18px' }} />
                                  <Typography>Add new action</Typography>
                                </Link>

                              )}
                              <Button
                                variant='text'

                                disableRipple
                                sx={{
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  marginTop: values.actions?.length > 0 ? 'auto' : '32px',
                                  padding: '6px 0px',
                                  '& svg': {
                                    color: '#0096ff !important'
                                  },
                                  '&:hover': {
                                    textDecoration: 'underline',
                                    color: '#0096ff',
                                    backgroundColor: 'transparent',
                                  },
                                }}
                                onClick={() => {
                                  setOpenImportModal(true);
                                  handleClose();
                                  setImportData(projectActions);
                                }}
                                disabled={isSubmitting}
                              >
                                <FileDownloadOutlinedIcon sx={{ fontSize: '18px' }} />
                                <Typography>Import action</Typography>
                              </Button>
                            </Stack>
                          )
                        )}
                        {values.actions?.length > 0 ? (
                          <Stack
                            direction='row'
                            justifyContent='center'
                            alignItems='center'
                            sx={{ marginTop: '8px' }}
                            spacing={4}
                          >
                            <Button
                              sx={{
                                width: '200px',
                              }}
                              variant='outlined'
                              onClick={handleClose}
                              disabled={isSubmitting}
                            >
                              Cancel
                            </Button>
                            <Button
                              sx={{
                                width: '200px',
                              }}
                              variant='contained'
                              type='submit'
                              disabled={isSubmitting || (user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR')}
                            >
                              Apply
                            </Button>
                          </Stack>
                        ) : (
                          <Stack
                            direction='row'
                            justifyContent='center'
                            alignItems='flex-end'
                            sx={{ marginTop: values.actions?.length > 0 ? '32px' : '0px' }}
                            spacing={4}
                          >
                            <Button
                              sx={{
                                width: '200px',
                              }}
                              variant='outlined'
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                            <Button
                              sx={{
                                width: '200px',
                              }}
                              variant='contained'
                              type='submit'
                              component={Link}
                              to='../feeds/enhance/new-action'
                              disabled={(user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR')}
                            >
                              Create
                            </Button>
                          </Stack>
                        )}
                      </Form>
                    );
                  }}
                </Formik>
              </FormGroup>
            }
            ErrorElement={
              <Box
                sx={{
                  position: 'absolute',
                  top: 'calc(50% + 25px)',
                  transform: 'translateY(-50%)',
                  width: '100%',
                }}
                textAlign='center'
              >
                <Typography variant='bodySemiBold'>
                  Error While Fetching Actions
                </Typography>
                <br />
                <Typography variant='bodySemiBold' sx={{ color: 'error.main' }}>
                  Please Try Again
                </Typography>
              </Box>
            }
            LoadingElement={
              <Stack width='100%' sx={{ padding: '16px' }}>
                <Skeleton variant='rectangular' animation="wave" width='100%' height={30} sx={{ borderRadius: '8px', marginBottom: '16px' }} />
                <Skeleton variant='rectangular' animation="wave" width='100%' height={30} sx={{ borderRadius: '8px', marginBottom: '16px' }} />
                <Skeleton variant='rectangular' animation="wave" width='100%' height={30} sx={{ borderRadius: '8px', marginBottom: '16px' }} />
                <Skeleton variant='rectangular' animation="wave" width='100%' height={30} sx={{ borderRadius: '8px', marginBottom: '16px' }} />
                <Skeleton variant='rectangular' animation="wave" width='100%' height={30} sx={{ borderRadius: '8px', marginBottom: '16px' }} />
              </Stack>
            }
          />
        </Box>
      </StyledFAModal>
      <ImportModal
        openImport={openImportModal}
        onClose={handleImportClose}
        importData={importData}
        importType='ACTION'
      />
    </>
  );
};

export { ActionMainModal };
