import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { object, string, boolean } from 'yup';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import { FormikField } from '../../../../widgets/FormikForm/FormikField';
import { HeadingWithUnderline } from '../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { useAddProjectFieldMutation } from '../../../../api/RTK/services/feedProject';
import { DataSendingModalBaseRTK } from '../../../../components/DataSendingModalBaseRTK';

const validationSchema = object({
  name: string()
    .required('Field name cannot be empty')
    .matches(
      /^[a-z0-9_]+$/,
      'Field name can contain only numbers and lowercase letters. Donot use space or dots in Field name.'
    ),
  data_type: string().required('Type is required'),
  is_list: boolean(),
});

const AddField = () => {
  const [open, setOpen] = useState(false);
  const handleCloseModal = () => setOpen(false);
  const { company } = useContext(UserInfoContext);
  const projectSelectId = useSelector(
    (State) => State.allProjectsRedux.projectId
  );

  const [addProjectField, { isLoading, isError, isSuccess, reset }] =
    useAddProjectFieldMutation();

  const { fileId } = useParams();
  const handleSubmit = (data) => {
    addProjectField({
      companyId: company.id,
      projectId: projectSelectId,
      fileId,
      name: data?.name,
      dataType: data?.data_type,
      isList: data?.is_list,
      requirements: data?.requirements,
    });
  };
  return (
    <>
      <Button
        onClick={() => setOpen(!open)}
        sx={{
          '&:hover': {
            backgroundColor: '#ffffff !important',
            textDecoration: 'underline',
          },
        }}
        size='small'
      >
        + Add New Field
      </Button>
      <DataSendingModalBaseRTK
        open={open}
        sx={{ width: '100%' }}
        handleCloseModal={handleCloseModal}
        isLoading={isLoading}
        isError={isError}
        isSuccess={isSuccess}
        successMessage='Added new field successfully'
        errorMessage='Error while adding a new field. Refresh and try again.'
        reset={reset}
      >
        <HeadingWithUnderline heading='Add New Field' />
        <Formik
          initialValues={{
            name: '',
            data_type: '',
            is_list: false,
            requirements: [],
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            touched,
            values,
            isSubmitting,
            handleChange,
            handleBlur,
          }) => (
            <Form noValidate>
              <Box
                display='flex'
                flexDirection='column'
                sx={{ mb: 2, FormikField: { label: { marginBottom: '15px' } } }}
              >
                <FormikField
                  name='name'
                  label={<div style={{ paddingBottom: '5px', textAlign: 'start' }}>Field Name</div>}
                  required
                  type='text'
                  placeholder='Enter Name'
                  value={values.name}
                  error={Boolean(errors.name) && touched.name}
                  helperText={touched.name && errors.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  fullWidth
                />
                <Typography variant='smallMedium' sx={{ paddingTop: '15px', textAlign: 'start' }}>
                  Type
                </Typography>
                <FormControl
                  error={Boolean(touched.data_type && errors.data_type)}
                >
                  <Field as={Select} name='data_type' displayEmpty>
                    <MenuItem value='' sx={{ color: '#BBB8B8' }}>
                      <em>Select an item</em>
                    </MenuItem>

                    <MenuItem value='TEXT'>Text</MenuItem>
                    <MenuItem value='NUMBER'>Number</MenuItem>
                    <MenuItem value='DATETIME'>Date & Time</MenuItem>
                    <MenuItem value='IMAGE'>Image</MenuItem>
                    <MenuItem value='GTIN'>GTIN</MenuItem>
                    <MenuItem value='OBJECT'>Object</MenuItem>
                    <MenuItem value='BOOLEAN'>Boolean</MenuItem>
                  </Field>
                  {touched.data_type && errors.data_type && (
                    <FormHelperText>{errors.data_type}</FormHelperText>
                  )}
                </FormControl>

                <FormControlLabel
                  control={<Field as={Checkbox} name='is_list' />}
                  label={
                    <Typography variant='smallMedium'>
                      This field contains multiple items
                    </Typography>
                  }
                />
              </Box>
              <Box
                sx={{ width: '100%' }}
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                gap={3}
              >
                <Button
                  variant='outlined'
                  onClick={() => setOpen(false)}
                  fullWidth
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  type='submit'
                  disabled={isLoading}
                  fullWidth
                >
                  Save & Import
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </DataSendingModalBaseRTK>
    </>
  );
};

export { AddField };
