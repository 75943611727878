import React, { useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import {
  Button,
  Chip,
  IconButton,
  MenuItem,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DeleteOutline, SubdirectoryArrowRight } from '@mui/icons-material';
import { DeleteField } from '../../OtherComponents/DeleteField';
import { DeleteImportedField } from '../../OtherComponents/DeleteImportedField';
import { CustomSelect } from './CustomSelect';
import { AddField } from '../../OtherComponents/AddField';
import { EditField } from '../../OtherComponents/EditField';
import { StyledMapTable } from '../../StyledComponents/StyledCommon';
import { UserInfoContext } from '../../../../../context/UserInfoContext';

const headCells = [
  {
    id: 'importField',
    numeric: false,
    labelformatFunction: () => (
      <Typography variant='smallBold'>Imported Fields</Typography>
    ),
    disablePadding: false,
    alignRight: false,
    formatFunction: (row) => row.name,
  },
  {
    id: 'projectField',
    labelformatFunction: () => (
      <Typography variant='smallBold'>Project Fields</Typography>
    ),
  },
  {
    id: 'type',
    labelformatFunction: () => (
      <Typography variant='smallBold'>Type</Typography>
    ),
  },
];

const headCellsCustom = [
  {
    id: 'importField',
    numeric: false,
    disablePadding: false,
    alignRight: false,
    labelformatFunction: () => '',
  },
  {
    id: 'projectField',
    labelformatFunction: () => (
      <Typography variant='smallBold'>Custom Project Fields</Typography>
    ),
  },
  {
    id: 'type',
    labelformatFunction: () => (
      <Typography variant='smallBold'>Type</Typography>
    ),
  },
  { id: 'editField', labelformatFunction: () => '' },

  { id: 'deleteField', labelformatFunction: () => '' },
];

const optionFormatter = (field) => {
  if (!field.pkid) {
    return (
      <MenuItem key={field.pkid} value={field.pkid}>
        <em>{field.name}</em>
      </MenuItem>
    );
  }

  return (
    <MenuItem key={field.pkid} value={field.pkid}>
      {field.name.includes('shipping.') ? field.name.split('.')[1] : field.name}
    </MenuItem>
  );
};

// startingIndex is added to the item index while calculating the formik path.
// Effective when 'importedFields' is a slice of 'value.importedFields' array
// and the Array.map indexes are shifted from their original positions
const ImportedFieldRowMapper = ({
  importedFields,
  startingIndex = 0,
  values,
  // openImportedModal,
  openModal,
}) => {
  const [selectedValues, setSelectedValues] = useState(
    importedFields.map(() => ({ selectedOption: { pkid: '', name: '' } }))
  );

  const { user } = useContext(UserInfoContext);

  useEffect(() => {
    const updatedSelectedValues = importedFields.map((row, index) => {
      const mappedProjectField = values.projectFields.find(
        (field) => field.pkid === row.mapped_to
      );

      const newSelectedOption = {
        pkid: row.mapped_to || '',
        name: mappedProjectField ? mappedProjectField.name : 'Do not Import',
        data_type: row.mapped_to === '' ? '--' : mappedProjectField?.data_type,
      };

      return { ...selectedValues[index], selectedOption: newSelectedOption };
    });

    setSelectedValues(updatedSelectedValues);
  }, [importedFields, values.projectFields]);
  // useEffect(() => {
  //   const updatedSelectedValues = importedFields.map((row, index) => {
  //     const mappedProjectField = mappedProjectFields[row.mapped_to];
  //     const newSelectedOption = {
  //       pkid: row.mapped_to || '',
  //       name: mappedProjectField ? mappedProjectField.name : 'Do not Import',
  //       data_type: row.mapped_to === '' ? '--' : mappedProjectField?.data_type,
  //     };

  //     return { ...selectedValues[index], selectedOption: newSelectedOption };
  //   });

  //   setSelectedValues(updatedSelectedValues);
  // }, [importedFields, mappedProjectFields, selectedValues]);

  return (
    <>
      {/* imported fields tablecells */}
      {importedFields.map((row, index) => (
        <TableRow
          hover
          tabIndex={-1}
          key={row.pkid}
          sx={{
            '&:last-child td, &:last-child th': { border: 0 },
          }}
          className='noVerticalBorders'
        >
          <>
            <TableCell
              component='th'
              scope='row'
              align='left'
              sx={{ minWidth: '215px' }}
            >
              <Stack direction='row' alignItems='center' gap={1}>
                {row.name.includes('shipping.') ? (
                  <>
                    <SubdirectoryArrowRight sx={{ ml: 2 }} />
                    {row.name.split('.')[1]}
                  </>
                ) : (
                  row.name
                )}
              </Stack>
            </TableCell>
            <TableCell>
              {row.present ? (
                <CustomSelect
                  name={`importedFields[${startingIndex + index}].mapped_to`}
                  options={[
                    { pkid: '', name: 'Do not Import' },
                    ...values.projectFields,
                  ].sort((a, b) => (a.name > b.name ? 1 : -1))}
                  formatter={optionFormatter}
                  disabled={
                    user?.role === 'COLLABORATOR' ||
                    user?.role === 'SUB_COLLABORATOR'
                  }
                />
              ) : (
                <Button
                  // onClick={() =>
                  //   openImportedModal(row.pkid , row.name)
                  // }
                  onClick={() => openModal(row.pkid, row.name)}
                >
                  Not in the import anymore. Delete
                </Button>
              )}
            </TableCell>

            <TableCell>
              <Chip
                label={
                  selectedValues[index].selectedOption.data_type
                    ? selectedValues[index].selectedOption.data_type
                        .charAt(0)
                        .toUpperCase() +
                      selectedValues[index].selectedOption.data_type
                        .slice(1)
                        .toLowerCase()
                    : ''
                }
                variant='outlined'
                size='small'
                sx={{
                  borderRadius: '4px',
                  fontWeight: '600',
                  color: '#323C46',
                  border: '1px solid #dedede',
                }}
              />

              {values.projectFields?.find(
                (field) => field.pkid === row.mapped_to
              ) &&
              values.projectFields.find((field) => field.pkid === row.mapped_to)
                ?.is_list ? (
                <Chip
                  label='List'
                  variant='outlined'
                  size='small'
                  sx={{
                    borderRadius: '4px',
                    fontWeight: '600',
                    color: '#323C46',
                    marginLeft: '8px',
                    border: '1px solid #dedede',
                  }}
                />
              ) : null}
            </TableCell>
          </>
        </TableRow>
      ))}
    </>
  );
};

const MapperTable = ({
  setIsFormDirty,
  setIsFormHasError,
  setPatchValue,
  refetch,
}) => {
  const {
    values,
    dirty,
    errors,
    // setValues, isSubmitting
  } = useFormikContext();
  const { user } = useContext(UserInfoContext);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDeleteImportedOpen, setIsDeleteImportedOpen] = useState(false);
  const [idForModal, setIdForModal] = useState(null);
  const [idForDeleteImportedModal, setIdForDeleteImportedModal] =
    useState(null);
  const [fieldToBeDeleted, setFieldToBeDeleted] = useState('');
  const [useFieldId, setUseFieldId] = useState(false);

  useEffect(() => {
    setIsFormDirty(dirty);
  }, [values, dirty]);
  useEffect(() => {
    setIsFormHasError(
      errors?.importedFields?.length > 0 || errors?.projectFields?.length > 0
    );
  }, [errors]);
  useEffect(() => {
    setPatchValue(values);
  }, [values.importedFields]);
  const openModal = (id, fieldName, custom) => {
    setIsDeleteOpen(true);
    setIdForModal(id);
    setFieldToBeDeleted(fieldName);
    setUseFieldId(!custom);
  };

  const closeModal = () => {
    setIsDeleteOpen(false);
    setIdForModal(null);
    setFieldToBeDeleted(null);
  };

  const openImportedModal = (id) => {
    setIsDeleteImportedOpen(true);
    setIdForDeleteImportedModal(id);
  };

  const closeImportedModal = () => {
    setIsDeleteImportedOpen(false);
    setIdForDeleteImportedModal(null);
  };

  const shippingIndex = values.importedFields
    .map((f) => f.name.split('.')[0])
    .indexOf('shipping');

  const customCreatedNames = values.projectFields.filter(
    (call) => call.custom === true
  );
  return (
    <>
      <TableContainer sx={{ border: '1px solid #dedede', borderRadius: '8px' }}>
        <StyledMapTable
          stickyHeader
          aria-labelledby='tableTitle'
          size='small'
          className='importTable'
        >
          <TableHead>
            <TableRow className='noVerticalBorders'>
              {headCells.map((headCell, index) => {
                const uniqueKey = `${headCell.id}_${index}`;
                if (index !== 3) {
                  return (
                    <TableCell
                      key={uniqueKey}
                      align={index === 0 ? 'left' : 'left'}
                    >
                      {headCell.labelformatFunction()}
                    </TableCell>
                  );
                }
                return <TableCell key={uniqueKey} align='left' />;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* split value.importedFields into two and add a shipping row in between if a imported fields contains a shipping field */}
            {shippingIndex > -1 ? (
              <>
                <ImportedFieldRowMapper
                  importedFields={values.importedFields.slice(0, shippingIndex)}
                  startingIndex={0}
                  values={values}
                  openImportedModal={openImportedModal}
                  openModal={openModal}
                />
                <TableRow
                  hover
                  tabIndex={-1}
                  key='shipping'
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                  className='noVerticalBorders'
                >
                  <TableCell
                    component='th'
                    scope='row'
                    align='left'
                    colSpan={4}
                  >
                    <Stack height='42px' direction='row' alignItems='center'>
                      shipping
                    </Stack>
                  </TableCell>
                </TableRow>
                <ImportedFieldRowMapper
                  importedFields={values.importedFields.slice(shippingIndex)}
                  startingIndex={shippingIndex}
                  values={values}
                  openImportedModal={openImportedModal}
                  openModal={openModal}
                />
              </>
            ) : (
              <ImportedFieldRowMapper
                importedFields={values.importedFields}
                startingIndex={0}
                values={values}
                openImportedModal={openImportedModal}
                openModal={openModal}
              />
            )}
            {!customCreatedNames.length > 0
              ? user?.role !== 'COLLABORATOR' &&
                user?.role !== 'SUB_COLLABORATOR' && (
                  <TableRow
                    sx={{
                      '& .MuiTableCell-root, &:last-child .MuiTableCell-root': {
                        border: 'none',
                        borderRight: 'none',
                        paddingBlock: 1,
                      },
                    }}
                  >
                    <TableCell />
                    <TableCell>
                      <AddField />
                    </TableCell>
                  </TableRow>
                )
              : null}
          </TableBody>
        </StyledMapTable>
      </TableContainer>

      {customCreatedNames.length > 0 ? (
        <TableContainer
          sx={{
            border: '1px solid #dedede',
            borderRadius: '8px',
            marginTop: '24px',
          }}
        >
          <StyledMapTable
            stickyHeader
            aria-labelledby='tableTitle'
            size='small'
            className='customTable'
          >
            <TableHead>
              <TableRow className='noVerticalBorders'>
                {headCellsCustom.map((headCellP, index) => {
                  const uniqueKey = `${headCellP.id}_${index}`;
                  if (index !== 3) {
                    return (
                      <TableCell
                        key={uniqueKey}
                        align={index === 0 ? 'left' : 'left'}
                      >
                        {headCellP.labelformatFunction()}
                      </TableCell>
                    );
                  }
                  return <TableCell key={uniqueKey} align='left' />;
                })}
              </TableRow>
            </TableHead>
            {/* custom created tablecells */}
            <TableBody>
              {customCreatedNames?.map((row, index) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row.pkid}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                  className='noVerticalBorders'
                >
                  <>
                    <TableCell component='th' scope='row' align='left'>
                      <Stack
                        direction='row'
                        alignItems='center'
                        gap={1}
                        sx={{ color: '#999999' }}
                      >
                        New Field
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <CustomSelect
                        name={row.pkid}
                        options={[
                          { pkid: '', name: 'Do not Import' },
                          ...values.projectFields,
                        ].sort((a, b) => (a.name > b.name ? 1 : -1))}
                        formatter={optionFormatter}
                        defaultValue={row.pkid}
                        disabled
                      />
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={
                          row.data_type.charAt(0).toUpperCase() +
                          row.data_type.slice(1).toLowerCase()
                        }
                        variant='outlined'
                        size='small'
                        sx={{
                          borderRadius: '4px',
                          fontWeight: '600',
                          color: '#323C46',
                          border: '1px solid #dedede',
                        }}
                      />
                      {row && row.is_list && (
                        <Chip
                          label='List'
                          variant='outlined'
                          size='small'
                          sx={{
                            borderRadius: '4px',
                            fontWeight: '600',
                            color: '#323C46',
                            marginLeft: '8px',
                            border: '1px solid #dedede',
                          }}
                        />
                      )}
                    </TableCell>

                    <TableCell>
                      <EditField
                        field={row}
                        index={index}
                        disabled={
                          user?.role === 'COLLABORATOR' ||
                          user?.role === 'SUB_COLLABORATOR'
                        }
                      />
                    </TableCell>

                    <TableCell>
                      <IconButton
                        onClick={() =>
                          openModal(row.pkid, row.name, row.custom)
                        }
                        disableRipple
                        sx={{
                          border: '1px solid #dedede',
                          borderRadius: '4px',
                          padding: '4px',
                        }}
                        disabled={
                          user?.role === 'COLLABORATOR' ||
                          user?.role === 'SUB_COLLABORATOR'
                        }
                      >
                        <DeleteOutline sx={{ fontSize: '20px' }} />
                      </IconButton>
                    </TableCell>
                  </>
                </TableRow>
              ))}
              {user?.role !== 'COLLABORATOR' &&
                user?.role !== 'SUB_COLLABORATOR' && (
                  <TableRow
                    sx={{
                      '& .MuiTableCell-root, &:last-child .MuiTableCell-root': {
                        border: 'none',
                        borderRight: 'none',
                        paddingBlock: 1,
                      },
                    }}
                  >
                    <TableCell />
                    {customCreatedNames.length > 0 ? (
                      <TableCell>
                        <AddField />
                      </TableCell>
                    ) : null}
                  </TableRow>
                )}
            </TableBody>
          </StyledMapTable>
        </TableContainer>
      ) : null}
      <DeleteField
        open={isDeleteOpen && typeof idForModal === 'number'}
        setOpen={setIsDeleteOpen}
        id={idForModal}
        handleClose={closeModal}
        fieldName={fieldToBeDeleted}
        useFieldId={useFieldId}
        refetch={refetch}
      />
      <DeleteImportedField
        open={
          isDeleteImportedOpen && typeof idForDeleteImportedModal === 'number'
        }
        setOpen={setIsDeleteImportedOpen}
        id={idForDeleteImportedModal}
        handleClose={closeImportedModal}
      />
    </>
  );
};

export { MapperTable };
