import { Box, Typography, Link, Paper } from '@mui/material';
import React from 'react';
import { Copyright } from '@mui/icons-material';
import { BadgeWrapper, FooterContainer } from './styles';
import MetaBusinessPartner from '../../assests/images/Other/MetaBusinessPartner.png';

const Footer = () => (
  <FooterContainer component='footer'>
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1ch',
        justifyContent: 'center',
        '@media (max-width:588px)': {
          flexDirection: 'column',
        },
        '@media (max-width: 337px)': {
          alignItems: 'start',
        },
      }}
    >
      <Typography
        variant='smallRegular'
        sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}
      >
        <Copyright sx={{ height: '0.875rem', width: '0.875rem' }} />
        AdHelp.io. All rights reserved.
      </Typography>
      <Box
        sx={{
          '@media (max-width:588px)': {
            display: 'none',
          },
        }}
      >
        |
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          gap: '1ch',
          justifyContent: 'center',
        }}
      >
        <Link
          href='https://www.adhelp.io/terms-and-conditions?hsLang=en'
          target='_blank'
          variant='footer'
        >
          Terms & Conditions.
          <Paper variant='screenReaderOnly'>Opens in a new tab</Paper>
        </Link>
        |
        <Link
          href='https://www.adhelp.io/privacy-policy?hsLang=en'
          target='_blank'
          variant='footer'
        >
          Privacy Policy.
          <Paper variant='screenReaderOnly'>Opens in a new tab</Paper>
        </Link>
      </Box>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '1ch' }}>
      <BadgeWrapper>
        <a
          href='https://www.facebook.com/business/partner-directory/search?solution_type=campaign_management&id=3887193928024572&section=overview'
          target='_blank'
          rel='noreferrer'
          className='partnerBadgeLink meta'
        >
          <img
            src={MetaBusinessPartner}
            alt='Meta Business Partner'
            className='partnerBadge'
          />
        </a>
      </BadgeWrapper>
      <BadgeWrapper>
        <a
          href='https://www.google.com/partners/agency?id=2411660382'
          target='_blank'
          rel='noreferrer'
          className='partnerBadgeLink'
        >
          <img
            src='https://www.gstatic.com/partners/badge/images/2022/PremierBadgeClickable.svg'
            alt='Google Partner - Premier 2022'
            className='partnerBadge'
          />
        </a>
      </BadgeWrapper>
    </Box>
  </FooterContainer>
);

export { Footer };

