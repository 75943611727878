import React, { useContext, useState } from 'react';
import _ from 'lodash';

// MUI imports
import { Box, Stack, Button } from '@mui/material';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

// Internal component import
import { useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import { PaginatedTable } from '../../../../components/PaginatedTable';
import { GlobalErrorComponent } from '../../../../components/GlobalErrorComponent';

// Styled component imports
import { StyledEnhanceTable } from '../StyledComponents/StyledCommon';
import { useGetDataPreviewQuery } from '../../../../api/RTK/services/feedFeed';
import { TitleCard } from '../GlobalComponents/TitlePaper';
import { SearchWithButton } from '../GlobalComponents/SearchWithButton';
import { AddColumnModal } from './AddColumnModal';

const renderCell = (val) =>
  typeof val === 'object' ? JSON.stringify(val) : val;

// accept data as the argument instead of accepting state and using state.data
const pickHeadCells = (data) =>
  data?.data[0]
    ? (Object.keys(data?.data[0]) && [
        {
          id: 'image',
          numeric: false,
          label: 'Image',
          disablePadding: false,
          alignRight: false,
          formatFunction: (row) => (
            <img
              // eslint-disable-next-line dot-notation
              src={row['image_link'] || row['g:image_link']}
              alt='item_image'
              height='auto'
              width='50'
            />
          ),
        },
        ...Object.keys(data?.data[0]).map((field) => ({
          id: field,
          numeric: false,
          label: _.startCase(field.replace('g:', '')),
          disablePadding: false,
          alignRight: false,
          formatFunction: (row) =>
            ['description', 'additional_image_link'].includes(field) ? (
              <Box
                as='span'
                sx={{
                  display: 'block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {renderCell(row[field])}
              </Box>
            ) : (
              renderCell(row[field])
            ),
        })),
      ]) ||
      []
    : [];

const FeedPreviewTable = () => {
    const [ isResizable , setResizable] = useState(Array.from({ length : 50 } ,  () => false));
    const [maxWidth, setMaxWidth] = useState(Array.from({ length : 50 } ,  () => 300));  const [searchTerm, setSearchTerm] = useState('');
  const [openCustomCol, setopenCustomCol] = useState(false);
  const [disable , setDisable] = useState(false)
  const { company } = useContext(UserInfoContext);
  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );

  const feedId = useSelector((state) => state.feedSummaryRedux.feedId);

  const handleSearchButtonClick = (value) => {
    setSearchTerm(value);
  };
   
  return (
    <>
      <StyledEnhanceTable>
        <Box sx={{ border: '1px solid #dedede', borderRadius: '4px' }}>
          <TitleCard
            title='Final Feed'
            paperSx={{ marginTop: '0px!important' }}
          >
            <Stack direction='row' spacing={2}>
              <SearchWithButton
                placeholder='Search'
                showEndAdornment
                onClick={handleSearchButtonClick}
              />
              <Button
                variant='pillOutlined'
                disableRipple
                sx={{
                  height: '44px',
                  minWidth: '250px',
                  '&:hover': { svg: { color: '#0096ff !important' } },
                  svg: { paddingRight: '5px', fontSize: '30px' },
                }}
                onClick={() => setopenCustomCol(true)}
                disabled={disable}
              >
                <EditNoteOutlinedIcon />
                Customise Columns
              </Button>
            </Stack>
          </TitleCard>
          <ErrorBoundary fallback={<GlobalErrorComponent />}>
            <PaginatedTable
              rowIdField='id'
              rowsPerPage={10}
              verticalBorders={false}
              displayFooter={false}
              styleBodyCell={(index) => ({
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: !isResizable[index] ? `${maxWidth[index]}px` : '10px',
                          })}
                          setMaxWidth={(index, value) => {
                            setMaxWidth(prevMaxWidth => {
                              const newMaxWidth = [...prevMaxWidth];
                              newMaxWidth[index] = value;
                              return newMaxWidth;
                            });
                          }}
                          setResizable={(index, value) => {
                            setResizable(prevResizable => {
                              const newResizable = [...prevResizable];
                              newResizable[index] = value;
                              return newResizable;
                            });
                }}
              fetcherHook={useGetDataPreviewQuery}
              args={{
                companyId: company.id,
                projectId: projectSelectId,
                feedId,
                search: searchTerm,
              }}
              linkRows
              linkFormatter={() => `./item-details`}
              linkStateFormatter={(row) => row}
              headCellGenerator={pickHeadCells}
              setDisable={setDisable}
            />
          </ErrorBoundary>
        </Box>
      </StyledEnhanceTable>
      <AddColumnModal
        openModel={openCustomCol}
        setModelOpen={setopenCustomCol}
        modalTitle='Add or Delete Columns'
        setftype=''
      />
    </>
  );
};

export { FeedPreviewTable };
