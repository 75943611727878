import React, { useEffect, useState, useContext } from 'react';
import { Container, Box, Button, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { FormikField } from '../../../widgets/FormikForm/FormikField';
import { TitleCard } from '../../FeedManagement/Components/GlobalComponents/TitlePaper';
import { StyledPaper } from '../../FeedManagement/PageViewComponents/StyledMainBox';
import { CategoryCheckbox } from '../../../widgets/SelectCategory/CategoryCheckbox';
import { FetchingDisplay } from '../../../components/FetchingDisplay';
import { GlobalLoadingComponent } from '../../../components/GlobalLoadingComponent';
import {
  useCreateUsersMutation,
  useEditUsersDetailsMutation,
  useGetUsersAccessDetailsQuery,
} from '../../../api/RTK/services/user';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { LoadingDialog } from '../Components/LoadingDialog';
import { AddAgencyUserSchema } from '../Schema/schemas';
import { GlobalErrorComponent } from '../../../components/GlobalErrorComponent';

const AddAgencyUser = () => {
  const { user } = useContext(UserInfoContext);
  const { id } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState('');
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    name: '',
    email: '',
    radioValue: '',
  });

  useEffect(() => {
    if (id) {
      setIsEdit(true);
    }
  }, [id]);

  const {
    data: getUser,
    isLoading: getLoading,
    isError: getError,
  } = useGetUsersAccessDetailsQuery({ id }, { skip: !isEdit });

  const [
    addUser,
    { isLoading: addLoading, isError: addIsError, error: addError },
  ] = useCreateUsersMutation();

  useEffect(() => {
    if (isEdit) {
      setInitialValues({
        name: getUser?.username || '',
        email: getUser?.email || '',
        radioValue:
          getUser?.role === 'ADMIN' ? 'agency_admin' : 'agency_collaborator',
      });
    }
  }, [getUser]);

  const [
    editUser,
    { isError: editIsError, isLoading: editLoading, error: editErrorMsg },
  ] = useEditUsersDetailsMutation(
    { skip: !isEdit },
    { refetchOnMountOrArgChange: true },
    { refetchOnReconnect: true },
    { forceRefetch: true }
  );

  const handleOnSubmit = async (values) => {
    const userType =
      values.radioValue === 'agency_admin' ? 'ADMIN' : 'COLLABORATOR';
    const requestData = isEdit
      ? { id, data: { user_type: userType, agency_id: user.projectId } }
      : {
          name: values.name,
          email: values.email,
          agency_id: user.projectId,
          userType,
        };

    const requestFunction = isEdit ? editUser : addUser;

    try {
      await requestFunction(requestData)
        .unwrap()
        .then(() => {
          setOpen(true);
          setTimeout(() => setOpen(false), 2999);
          setTimeout(() => navigate('/users/agency'), 3000);
        })
        .catch(() => {
          setOpen(true);
        });
    } catch (errors) {
      console.error('Error occurred:', errors);
    }
  };

  const data = [
    {
      id: 1,
      value: 'agency_admin',
      labels: ['Accounts', 'Dashboard', 'Feed Management', 'Users', 'Payment'],
      labelSecond: [
        'Link, Unlink & View',
        'View & Customize',
        'Create, Edit & Delete all projects, Feed, Actions, Filters, Schedule',
        'Add, Edit & Delete',
        'Make payment, View history',
      ],
    },
    {
      id: 2,
      value: 'agency_collaborator',
      labels: ['Accounts', 'Dashboard', 'Feed Management', 'Users', 'Payment'],
      labelSecond: ['View', 'View & Customize', 'View', 'View', 'View history'],
    },
  ];

  return (
    <FetchingDisplay
      isLoading={getLoading}
      isError={getError}
      ErrorElement={<GlobalErrorComponent />}
      LoadingElement={<GlobalLoadingComponent />}
      SuccessElement={
        <Container maxWidth='xxl' sx={{ marginTop: '30px' }}>
          <TitleCard title='Agency User Access' />
          <StyledPaper>
            <Box>
              <Formik
                enableReinitialize
                validateOnBlur={false}
                initialValues={initialValues}
                validationSchema={AddAgencyUserSchema}
                onSubmit={handleOnSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit} noValidate autoComplete='off'>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <FormikField
                          required
                          placeholder='Enter Name'
                          name='name'
                          type='text'
                          value={values.name}
                          label='Name'
                          error={Boolean(errors.name) && touched.name}
                          helperText={touched.name && errors.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isEdit}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormikField
                          required
                          placeholder='Enter Email ID'
                          name='email'
                          type='email'
                          value={values.email}
                          label='Email'
                          error={Boolean(errors.email) && touched.email}
                          helperText={touched.email && errors.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isEdit}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <CategoryCheckbox
                          data={data}
                          value={values.radioValue}
                          setValue={(newValue) => {
                            setValue(newValue);
                            handleChange({
                              target: { name: 'radioValue', value: newValue },
                            });
                          }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name='radioValue'
                          error={errors.radioValue}
                          variant='smallRegularFaded'
                        />
                      </Grid>
                    </Grid>
                    <Box
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      gap={3}
                      mt={3}
                    >
                      <Button
                        variant='outlined'
                        onClick={() => navigate(-1)}
                        sx={{ minWidth: '200px', maxWidth: '220px', p: 1 }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type='submit'
                        variant='contained'
                        sx={{ minWidth: '200px', maxWidth: '220px', p: 1 }}
                      >
                        Save
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </StyledPaper>
          <LoadingDialog
            open={open}
            isLoading={isEdit ? editLoading : addLoading}
            isError={isEdit ? editIsError : addIsError}
            onClose={() => setOpen(false)}
            successMessage={isEdit ? 'Access Edited' : 'Invite Send'}
            errorMessage={
              (isEdit
                ? editErrorMsg?.data?.error?.detail
                : addError?.data?.error?.detail) || 'Error'
            }
          />
        </Container>
      }
    />
  );
};

export { AddAgencyUser };
