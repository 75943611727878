/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-else-return */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

// MUI imports
import {
  Box,
  FormGroup,
  Paper,
  Typography,
  Button,
  Stack,
  IconButton,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { DragIndicatorOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';

// Internal component imports
import { CustomiseColumnModal } from './CustomiseColumnModal';
import { DynamicSelectBox } from '../GlobalComponents/DynamicSelectBox';

// Styled imports
import {
  StyledCustomColumnModel,
  StyledLoadingCircle,
} from '../StyledComponents/StyledCommon';

import {
  updateDesirdColumn,
  updateDesirdColumnInAffectedItems,
  updateDesirdColumnInEnhance,
  updateDesirdColumnInPreview,
} from '../../Redux/CustomizableHeadcells/All-Items/slice';

// function to select the correct state based on the location

const columnSelector = (location) => {
  if (location.pathname.includes('/feed-management/feeds/enhance')) {
    return useSelector(
      (state) => state.allItemCustomizedColumnSlice.enhaceColumn
    );
  } else if (location.pathname.includes('/feed-management/feeds/preview')) {
    return useSelector(
      (state) => state.allItemCustomizedColumnSlice.previewColumn
    );
  } else if (
    location.pathname.includes('/feed-management/feeds/affected-items')
  ) {
    return useSelector(
      (state) => state.allItemCustomizedColumnSlice.affectedItemsColumn
    );
  }
  return useSelector(
    (state) => state.allItemCustomizedColumnSlice.allItemsColumn
  );
};

const columnTobeRenderdSelector = (location) => {
  if (location.pathname.includes('/feed-management/feeds/enhance')) {
    return useSelector(
      (state) => state.allItemCustomizedColumnSlice.columnTobeRenderdInEnhance
    );
  } else if (location.pathname.includes('/feed-management/feeds/preview')) {
    return useSelector(
      (state) => state.allItemCustomizedColumnSlice.columnTobeRenderdInPreview
    );
  } else if (
    location.pathname.includes('/feed-management/feeds/affected-items')
  ) {
    return useSelector(
      (state) =>
        state.allItemCustomizedColumnSlice.columnTobeRenderdInAffectedItems
    );
  }
  return useSelector(
    (state) => state.allItemCustomizedColumnSlice.columnTobeRenderd
  );
};

const mergeRefs =
  (...refs) =>
  (node) => {
    refs.forEach((reference) => {
      if (typeof reference === 'function') {
        reference(node);
      } else if (reference != null) {
        // eslint-disable-next-line no-param-reassign
        reference.current = node;
      }
    });
  };

const AddColumnModal = ({ modalTitle, setftype, openModel, setModelOpen }) => {
  const [errorMsg, setErrorMsg] = useState(
    'There should be at least one column'
  );
  const location = useLocation();
  const [showLoading, setShowLoading] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  const formGroupRef = useRef(null);

  const allItemsColumn = columnSelector(location);
  const headCelles = columnTobeRenderdSelector(location);
  const result = allItemsColumn.map((item) => ({
    title: item.label,
    value: item.label,
  }));
  const [openCustomCol, setCustomCol] = useState(false);

  const handleCloseAddColModal = () => setModelOpen(false);

  // Formik initial values
  const initialValues = {
    columns: headCelles.filter((el) => el).map((item) => item?.label),
  };
  const validationSchema = Yup.object({
    columns: Yup.array().test(
      'is-unique',
      'Column must be unique',
      function (value) {
        if (!value) return true;
        const uniqueValues = new Set();
        const errors = value.map((col, index) => {
          if (col && uniqueValues.has(col)) {
            // Mark the specific index as invalid
            return this.createError({
              path: `${this.path}[${index}]`,
              message: 'Column must be unique',
            });
          }
          uniqueValues.add(col);
          return null;
        });

        if (errors.some((error) => error !== null)) {
          throw new Yup.ValidationError(
            errors.filter((error) => error !== null)
          );
        }

        return true;
      }
    ),
  });
  const applyScroll = () => {
    if (scroll) {
      formGroupRef.current.scrollTo({
        top: formGroupRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };
  // Form submission handler
  const handleSubmit = (values) => {
    let updatedColumns;
    const filterarr = values.columns.filter((el) => el !== '');
    if (values.columns.length >= 1 && !filterarr[0]) {
      setButtonDisabled(true);
      setErrorMsg('The Column should not be empty');
      setShowLoading(false);
      return;
    } else {
      setButtonDisabled(false);
      updatedColumns = values.columns
        .map((col) => col.toLowerCase())
        .filter((col) => col.trim() !== '');
    }
    setShowLoading(true);
    setTimeout(() => {
      if (location.pathname.includes('/feed-management/feeds/enhance')) {
        dispatch(updateDesirdColumnInEnhance(updatedColumns));
      } else if (location.pathname.includes('/feed-management/feeds/preview')) {
        dispatch(updateDesirdColumnInPreview(updatedColumns));
      } else if (
        location.pathname.includes('/feed-management/feeds/affected-items')
      ) {
        dispatch(updateDesirdColumnInAffectedItems(updatedColumns));
      } else {
        dispatch(updateDesirdColumn(updatedColumns));
      }
      setModelOpen(false);
      setShowLoading(false);
      setButtonDisabled(false);
    }, 1000);
  };

  // Handle drag end to update the order of columns
  const handleOnDragEnd = (result, values, setFieldValue) => {
    if (!result.destination) return;
    const items = Array.from(values.columns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFieldValue('columns', items);
  };
  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    // position: 'static',
    top: 'auto',
    left: '0',
    margin: isDragging ? '0 4px' : '2px 0 16px 0',
    userSelect: 'none',
    transform: isDragging
      ? `${draggableStyle.transform} rotate(0.01deg)`
      : draggableStyle.transform,
  });

  return (
    <>
      <StyledCustomColumnModel
        open={openModel}
        onClose={handleCloseAddColModal}
        BackdropProps={{ style: { backdropFilter: 'blur(3px)' } }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '500px',
        }}
      >
        {showLoading ? (
          <Box
            className='modalBox'
            component={Paper}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <StyledLoadingCircle sx={{ zIndex: '9999999999' }} />
          </Box>
        ) : (
          <Box
            className='modalBox'
            component={Paper}
            sx={{ overflowY: 'auto' }}
          >
            <Typography variant='h4' className='modalHeading'>
              {modalTitle}
            </Typography>
            <Typography variant='h6' className='modalSubHeading'>
              Select Columns
            </Typography>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                handleChange,
                setFieldValue,
                errors,
                handleBlur,
              }) => {
                useEffect(() => {
                  if (errors?.columns) {
                    setScroll(true);
                  } else if (!errors?.columns?.length) {
                    setScroll(false);
                  }
                }, [errors?.columns?.length]);
                useEffect(() => {
                  if (values.columns.length > 1) {
                    setButtonDisabled(false);
                  }
                }, [values?.columns?.length]);
                useEffect(() => {
                  const filterarr = values.columns.filter((el) => el !== '');
                  if (values.columns.length >= 1 && filterarr[0]) {
                    setButtonDisabled(false);
                  }
                }, [values?.columns]);
                return (
                  <Form sx={{ position: 'relative' }}>
                    <DragDropContext
                      onDragEnd={(result) => {
                        handleOnDragEnd(result, values, setFieldValue);
                      }}
                    >
                      <Droppable droppableId='columnsList'>
                        {(provided) => (
                          <FormGroup
                            sx={{
                              display: 'block',
                              minHeight: '65px',
                              height:
                                values.columns.length > 5 ? '300px' : 'auto',
                              paddingBottom:
                                values.columns.length > 5 ? '50px' : '0px',

                              overflowY: 'auto',
                              marginTop: '15px',
                              paddingRight: '20px',
                              position: 'relative',
                            }}
                            ref={mergeRefs(provided.innerRef, formGroupRef)}
                            {...provided.droppableProps}
                          >
                            {values.columns.map((column, index) => (
                              <Draggable
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                draggableId={`draggable-${index}`}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <Stack
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    direction='row'
                                    alignItems='center'
                                    justifyContent='space-between'
                                    sx={{
                                      width: '100%',
                                      border: '1px solid #DEDEDE',
                                      height: '40px',
                                      marginBottom: '25px',
                                      borderRadius: '4px',
                                      backgroundColor: '#ffffff',
                                      padding: '2px 10px',
                                      zIndex: snapshot.isDragging
                                        ? 9999999999999
                                        : 'auto',

                                      ...provided.draggableProps.style,
                                    }}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <Stack
                                      direction='row'
                                      alignItems='center'
                                      sx={{
                                        width: '100%',
                                        position: 'relative',
                                      }}
                                    >
                                      <DragIndicatorOutlined
                                        sx={{
                                          color: '#dedede',
                                          marginRight: '12px',
                                          marginLeft: '-1px',
                                          fontSize: '20px',
                                          marginTop: '2px',
                                        }}
                                      />
                                      <Field
                                        name={`columns.${index}`}
                                        component={({ field }) => (
                                          <DynamicSelectBox
                                            className='projectSelect'
                                            custmiseBox={location.pathname}
                                            setftype={setftype}
                                            name={`columns.${index}`}
                                            placeholderText='Select a Column'
                                            value={field.value}
                                            selectedItem={result.filter(
                                              (item) =>
                                                !values.columns
                                                  .filter((el) => el)
                                                  .includes(item.label)
                                            )}
                                            handleChange={(event) => {
                                              const { value } = event.target;
                                              setFieldValue(
                                                `columns.${index}`,
                                                value
                                              );
                                            }}
                                            handleBlur={handleBlur}
                                            error={
                                              errors.columns &&
                                              errors.columns[index] ? (
                                                <Box xs={{ marginTop: '5px' }}>
                                                  {errors.columns}
                                                </Box>
                                              ) : (
                                                ''
                                              )
                                            }
                                            index={
                                              errors.columns &&
                                              errors.columns[index] &&
                                              index
                                            }
                                          />
                                        )}
                                      />
                                      <IconButton
                                        className='columnDel'
                                        sx={{
                                          '&:hover': {
                                            backgroundColor:
                                              '#ffffff !important',
                                          },
                                        }}
                                        onClick={() => {
                                          if (values.columns?.length === 1) {
                                            setErrorMsg(
                                              'There should be at least one column'
                                            );
                                            setButtonDisabled(true);
                                          } else {
                                            const updatedColumns =
                                              values.columns.filter(
                                                (_, idx) => idx !== index
                                              );
                                            setFieldValue(
                                              'columns',
                                              updatedColumns
                                            );
                                          }
                                        }}
                                      >
                                        <DeleteOutlineIcon fontSize='20' />
                                      </IconButton>
                                    </Stack>
                                  </Stack>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder && (
                              <div style={{ display: 'none' }}>
                                {provided.placeholder}
                              </div>
                            )}
                            {buttonDisabled && (
                              <Typography
                                variant='smallerRegular'
                                color='error'
                                sx={{
                                  position: 'absolute',
                                  top: '41px',
                                  marginBottom: '20px',
                                }}
                              >
                                {errorMsg}
                              </Typography>
                            )}
                          </FormGroup>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <Stack
                      justifyContent='space-between'
                      direction='row'
                      sx={{
                        height: '40px',
                      }}
                    >
                      <Button
                        className='addbutton'
                        variant='text'
                        disableRipple
                        sx={{
                          justifyContent: 'flex-start',
                          width: {
                            lg: '250px !important',
                            md: '200px !important',
                          },
                          padding: '10px 0px',
                          svg: { color: '#0096ff !important' },
                          '&:hover': {
                            backgroundColor: '#ffffff !important',
                          },
                        }}
                        onClick={() => {
                          handleChange({
                            target: {
                              name: 'columns',
                              value: [...values.columns, ''],
                            },
                          });
                          // scroll when a new column added
                          formGroupRef.current.scrollTo({
                            top: formGroupRef.current.scrollHeight,
                            behavior: 'smooth',
                          });
                        }}
                      >
                        <AddIcon
                          sx={{ fontSize: '18px', marginRight: '8px' }}
                        />
                        Add existing column
                      </Button>
                    </Stack>

                    <Stack
                      direction='row'
                      justifyContent='space-between'
                      alignItems='center'
                      sx={{ marginTop: '20px' }}
                    >
                      <Button
                        sx={{
                          width: '215px',
                          margin: '5px',
                        }}
                        variant='outlined'
                        onClick={() => {
                          setModelOpen(false);
                          setButtonDisabled(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        sx={{
                          width: '215px',
                          margin: '5px',
                        }}
                        type='submit'
                        variant='contained'
                        onClick={applyScroll}
                      >
                        Apply
                      </Button>
                    </Stack>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        )}
      </StyledCustomColumnModel>
      <CustomiseColumnModal
        setCol={setModelOpen}
        openCustomCol={openCustomCol}
        setCustomCol={setCustomCol}
        modalTitle='New Column'
      />
    </>
  );
};

export { AddColumnModal };
