// convert null to '' for mapped_to
// use '' in mapped_to if imports is false
const preFormatter = (importedFields) =>
  importedFields.map((field) => {
    if (!field.mapped_to) {
      return {
        ...field,
        mapped_to: '',
      };
    }
    return field;
  });

const formatter = (values) => ({
  fields: values.importedFields.map((field) => {
    // set mapped_to to null if it is an empty string or if imports is false
    if (!field.mapped_to) {
      return {
        ...field,
        mapped_to: null,
        // imports: false,
      };
    }
    return field;
  }),
  combineScheme: {
    join_type: values.join_type,
    join_on: values.join_on || null,
  },
});

export { preFormatter, formatter };
