import React, { useState, useContext } from 'react';
import {
  Backdrop,
  Fade,
  Button,
  Box,
  Typography,
  MenuItem,
  InputLabel,
} from '@mui/material';

import { Form, Formik } from 'formik';
import { MetaPlatformSchema } from '../../../../../Components/Forms/Schema';
import { FormikField } from '../../../../../Components/Forms/FormikField';
import { AccountsService } from '../../../../../../../services/accounts';

import {
  StyledGlobalModal,
  StyledModal,
  StyledNavigationBox,
} from '../utils/StyledPlatformComponents';

import { currencies } from '../../../../../../POAS/POASDataboxes/POASDemoDashboard/POASCalculator/CurrencyList';
import { StyledWrapper, StyledSelect } from '../utils/StyledGoogleAnalytics';

import { LogoHeadingWithUnderline } from '../../../../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { VideoCard, LinkComponent } from '../utils/PlatformComponents';
import { CloseButton } from '../../../../../../../widgets/CloseButton';
import { FacebookInvite } from '../../../../../../../constants/endpoints/assests/video';
import { MetaIconLogo } from '../../../../../../../constants/endpoints/assests/logos';
import { UserInfoContext } from '../../../../../../../context/UserInfoContext';
import { LoadingDialog } from '../../../../../../UserManagement/Components/LoadingDialog';

const MetaPlatform = ({ open, handleCloseModal, setAssociation }) => {
  const initialValues = {
    name: '',
    email: '',
    ads_account_id: '',
    currency_code: 'INR',
  };

  const { company } = useContext(UserInfoContext);
  const [errorMessage, setErrorMessage] = useState('');

  const [accountsError, setAccountsError] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleOnSubmit = async (values, formikHelpers) => {
    try {
      formikHelpers.resetForm();
      const payload = {
        data: [
          {
            customer_id: values?.ads_account_id,
            name: values?.name,
            platform: 'META_ADS',
            currency_code: values?.currency_code,
          },
        ],
        company_id: company ? company.id : null,
      };
      const response = await AccountsService.createAccounts(payload);
      if (response.status === 201) {
        setOpenModal(true);
        setTimeout(() => {
          setOpenModal(false);
          setAssociation((association) => association + 1);
          handleCloseModal();
        }, 5000);
      } else {
        setOpenModal(true);
        setAccountsError(true);
        setTimeout(() => {
          setOpenModal(false);
          handleCloseModal();
        }, 5000);
      }
    } catch (error) {
      setAccountsError(true);
      setOpenModal(true);
      setErrorMessage(
        error?.response?.data?.errors?.[0]?.[values?.name]?.[0] ||
          'An error occurred while connecting your account. Please try again later.'
      );
      setTimeout(() => {
        setOpenModal(false);
      }, 5000);
    }
  };

  return (
    <>
      <StyledModal
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <StyledGlobalModal sx={{ my: 2 }}>
            <LogoHeadingWithUnderline
              src={MetaIconLogo}
              heading='Link Meta Platforms'
            />
            <Box>
              <Typography variant='smallRegular' textAlign='center'>
                Refer to the video below to know where to get the details from.
              </Typography>
              <VideoCard src={FacebookInvite} />
            </Box>
            <Box>
              <Formik
                initialValues={initialValues}
                onSubmit={handleOnSubmit}
                validationSchema={MetaPlatformSchema}
              >
                {({
                  values,
                  dirty,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                }) => (
                  <Form>
                    <Box sx={{ height: (theme) => theme.spacing(10.8) }}>
                      <FormikField
                        name='name'
                        label='Business Name'
                        required
                        type='text'
                        placeholder='Enter your business name'
                        value={values.name}
                        error={Boolean(errors.name) && touched.name}
                        helperText={touched.name && errors.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Box>
                    <Box sx={{ height: (theme) => theme.spacing(10.8) }}>
                      <FormikField
                        name='email'
                        label='Email Id'
                        required
                        type='email'
                        placeholder='Enter Email ID linked to Meta platform'
                        value={values.email}
                        error={Boolean(errors.email) && touched.email}
                        helperText={touched.email && errors.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Box>
                    <Box sx={{ height: (theme) => theme.spacing(10.8) }}>
                      <FormikField
                        name='ads_account_id'
                        label='Ads Account ID'
                        required
                        type='text'
                        placeholder='Enter your Ads Account ID'
                        value={values.ads_account_id}
                        error={
                          Boolean(errors.ads_account_id) &&
                          touched.ads_account_id
                        }
                        helperText={
                          touched.ads_account_id && errors.ads_account_id
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Box>
                    <StyledWrapper display='flex' alignItems='center'>
                      <InputLabel
                        htmlFor='select-standard'
                        sx={{
                          '&.MuiInputLabel-root': {
                            color: ' rgba(0, 0, 0, .8)',
                          },
                        }}
                      >
                        <Typography variant='smallBold'>Currency</Typography>
                        <StyledSelect
                          labelId='select-standard-label'
                          id='select-standard'
                          name='currency_code'
                          value={values?.currency_code}
                          onChange={handleChange}
                          variant='standard'
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxHeight: '250px',
                                width: 'auto',
                                overflowY: 'scroll',
                                '.MuiMenuItem-root': { fontSize: '0.85rem' },
                              },
                            },
                          }}
                          sx={{ mt: 0.5 }}
                        >
                          {Object.keys(currencies).map((item) => (
                            <MenuItem
                              key={currencies[item].code}
                              value={currencies[item].code}
                              sx={{ px: 1, py: 0.5 }}
                            >
                              {currencies[item].code}
                            </MenuItem>
                          ))}
                        </StyledSelect>
                      </InputLabel>
                    </StyledWrapper>
                    <Button
                      variant='contained'
                      type='submit'
                      disabled={!dirty}
                      fullWidth
                      disableFocusRipple
                      disableRipple
                      sx={{ mt: 1 }}
                    >
                      Link
                    </Button>
                  </Form>
                )}
              </Formik>
            </Box>
            <StyledNavigationBox>
              <LinkComponent
                text='Go to Meta Platform'
                to='https://www.facebook.com/business/tools/ads-manager'
              />
            </StyledNavigationBox>
            <CloseButton onClick={handleCloseModal} />
          </StyledGlobalModal>
        </Fade>
      </StyledModal>
      <LoadingDialog
        open={openModal}
        isError={accountsError}
        onClose={() => setOpenModal(false)}
        successMessage='Your account link request has been successfully submitted. Our team will work to link it as soon as possible.'
        errorMessage={errorMessage}
      />
    </>
  );
};

export { MetaPlatform };
