import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// MUI imports
import {
  Stack,
  Button,
  TextField,
  Select,
  MenuItem,
  SvgIcon,
  Typography,
  InputAdornment,
  IconButton,
  Tooltip,
  Box,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// Internal imports
import { ReactComponent as XML } from '../../../../assests/images/logos/XML.svg';
import { ReactComponent as CSV } from '../../../../assests/images/logos/CSV.svg';
import { FeedPreviewTable } from './FeedPreviewTable';
import { useExportAsMutation } from '../../../../api/RTK/services/feedFeed';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import { DataSendingModalBaseRTK } from '../../../../components/DataSendingModalBaseRTK';

const docType = ['XML', 'CSV'];

const downImg = { XML: <XML />, CSV: <CSV /> };

const FeedsPreviewList = ({ data }) => {
  const navigate = useNavigate();
  const { company } = useContext(UserInfoContext);

  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );
  const feedId = useSelector((state) => state?.feedSummaryRedux?.feedId);

  const handleFeedSummary = () => {
    navigate('../feeds');
  };
  const feedSelectChannel = useSelector(
    (state) => state.feedSummaryRedux.feedChannel
  );

  const [
    exportas,
    { isLoading: csvLoading, isSuccess: csvSuccess, isError: csvError, reset },
  ] = useExportAsMutation();

  const [copied, setCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [downloadAs, setDownloadAs] = useState('');
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setIsLoading(false);
    setIsError(false);
    setIsSuccess(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleSelectChange = (event) => {
    setDownloadAs(event.target.value);

    if (event.target.value === 'XML') {
      const fileName = `${data?.file_url}.${event.target.value.toLowerCase()}`;
      setIsLoading(true);

      fetch(data?.file_url, {
        method: 'GET',
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then((blob) => {
          const blobUrl = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = fileName;
          link.click();
          setIsLoading(false);
          setIsSuccess(true);
          handleOpen();
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          setIsError(true);
          handleOpen();
        });
    }
    if (event.target.value === 'CSV') {
      exportas({
        companyId: company?.id,
        projectId: projectSelectId,
        feedId,
      })
        .unwrap()
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'file.csv');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch(() => {
          console.error('Error while downloading file.csv');
        });
    }
  };

  useEffect(() => {
    if (
      isLoading ||
      isError ||
      isSuccess ||
      csvLoading ||
      csvSuccess ||
      csvError
    ) {
      handleOpen();
    }
  }, [isLoading, isError, isSuccess, csvLoading, csvSuccess, csvError]);

  const handleCopy = () => {
    window.navigator.clipboard.writeText(data?.file_url);
    setCopied(true);
    // Set a timeout to reset the copied state after 10 seconds
    setTimeout(() => {
      setCopied(false);
    }, 500);
  };

  const handleChannelName = () => {
    if (feedSelectChannel === 'GOOGLE_SHOPPING') {
      return <span>Google Merchant Center</span>;
    }
    if (feedSelectChannel === 'META_ADS') {
      return <span>Meta E-commerce Manager</span>;
    }
    if (feedSelectChannel === 'PINTEREST') {
      return <span>Pinterest Catalogue</span>;
    }
    if (feedSelectChannel === 'TIKTOK') {
      return <span>TikTok Catalogue</span>;
    }
    if (feedSelectChannel === 'GOOGLE_DYNAMIC_SEARCH_ADS') {
      return <span>Google Ads</span>;
    }
    return null;
  };

  return (
    <>
      <Typography sx={{ marginBottom: '8px' }} variant='smallMedium'>
        Copy-paste the following URL in {handleChannelName()}
      </Typography>
      <Stack
        sx={{
          width: '100%',
          marginBottom: '20px',
        }}
        direction={{ xs: 'column', lg: 'row' }}
        alignItems='center'
        justifyContent='center'
        gap={2}
      >
        <TextField
          value={data?.file_url}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Tooltip title='Copy to clipboard' placement='bottom'>
                  <IconButton disableRipple onClick={handleCopy}>
                    <ContentCopyIcon
                      sx={{
                        fontSize: '16px',
                        color: '#999999',
                        cursor: 'copy',
                      }}
                    />
                  </IconButton>
                </Tooltip>
                {copied && (
                  <Tooltip title='Copied!' placement='bottom'>
                    <span style={{ color: 'green' }}>Copied!</span>
                  </Tooltip>
                )}
              </InputAdornment>
            ),
          }}
        />

        <Select
          value={downloadAs}
          displayEmpty
          onChange={handleSelectChange}
          sx={{
            width: '200px',
            '@media (max-width: 1025px)': {
              width: '100%',
            },
          }}
        >
          <MenuItem value=''>
            <em>Download as</em>
          </MenuItem>
          {docType.map((type) => (
            <MenuItem key={type} value={type}>
              <Stack flexDirection='row' alignItems='center' gap={0.5}>
                <SvgIcon fontSize='16px'>{downImg[type]}</SvgIcon>
                {type}
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <FeedPreviewTable />
      <Stack direction='row' justifyContent='center' alignItems='center'>
        <Button
          sx={{
            width: '200px',
            margin: '25px 5px',
          }}
          variant='outlined'
          onClick={() => navigate('../feeds')}
        >
          Back
        </Button>
        <Button
          sx={{
            width: '200px',
            margin: '25px 5px',
          }}
          variant='contained'
          onClick={handleFeedSummary}
        >
          Go to Feeds
        </Button>
      </Stack>
      <DataSendingModalBaseRTK
        open={open}
        handleCloseModal={handleClose}
        isLoading={isLoading || csvLoading}
        isSuccess={isSuccess || csvSuccess}
        isError={isError || csvError}
        successMessage='Downloaded successfully'
        errorMessage='Error while downloading the file.'
        reset={() => {
          reset();
        }}
      >
        <Box sx={{ height: '200px' }} />
      </DataSendingModalBaseRTK>
    </>
  );
};
export { FeedsPreviewList };
