import React, { useContext, useMemo, useState } from 'react';
// MUI imports
import { Box, Button, Container } from '@mui/material';

// Internal imports
import { useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { TitleCard } from '../Components/GlobalComponents/TitlePaper';
import { ToggleButtonSettings } from '../Components/OtherComponents/ToggleButtonSettings';
import { ScheduleForm } from '../Components/OtherComponents/ScheduleForm';

// Styled imports
import { StyledPaper } from './StyledMainBox';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { FetchingDisplay } from '../../../components/FetchingDisplay';
import { GlobalLoadingComponent } from '../../../components/GlobalLoadingComponent';
import { PageErrors } from '../../../components/PageErrors';
import {
  useCreateScheduleMutation,
  useEditProjectMutation,
  useGetProjectDetailsQuery,
  useGetScheduleQuery,
  useUpdateScheduleMutation,
} from '../../../api/RTK/services/feedProject';
import { DataSendingModalBaseRTK } from '../../../components/DataSendingModalBaseRTK';
import { ReactComponent as Avocado500 } from '../../../assests/images/artWork/Avocado500.svg';

const ActivateProjectButton = ({ projectData, companyId, user }) => {
  const projectFeeds = useSelector((state) => state.allProjectsRedux.feeds);
  const areAllFeedsInactive =
    (projectFeeds && projectFeeds.length === 0) ||
    (projectFeeds &&
      projectFeeds.length > 0 &&
      projectFeeds.every((feed) => !feed.active));

  const [editProject, { isLoading, isError, reset, isSuccess }] =
    useEditProjectMutation();

  const activateProject = () => {
    editProject({
      companyId,
      data: {
        active: projectData.active,
        feedproject_id: projectData.id,
      },
    });
  };

  return (
    <>
      <Button
        onClick={activateProject}
        variant='outlined'
        disabled={
          areAllFeedsInactive ||
          user?.role === 'COLLABORATOR' ||
          user?.role === 'SUB_COLLABORATOR'
        }
      >
        Activate this project
      </Button>
      <DataSendingModalBaseRTK
        open={isLoading || isSuccess || isError}
        handleCloseModal={reset}
        isSuccess={isSuccess}
        isError={isError}
        isLoading={isLoading}
        reset={reset}
        successMessage='Activated Successfully'
        errorMessage={"Couldn't Activate"}
      >
        <Box sx={{ height: '200px' }} />
      </DataSendingModalBaseRTK>
    </>
  );
};

const Schedule = () => {
  const [toggled, setToggled] = useState('Schedule');
  const { company, user } = useContext(UserInfoContext);

  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );

  const feedSelectId = useSelector((state) => state.feedSummaryRedux.feedId);

  // get request
  const memoizedArgs = useMemo(
    () => ({
      companyId: company?.id,
      projectId: projectSelectId,
      feedId: feedSelectId,
    }),
    [company?.id, projectSelectId, feedSelectId]
  );

  const {
    isError,
    isLoading,
    data: projectDetails,
  } = useGetProjectDetailsQuery(
    {
      companyId: company.id,
      projectId: projectSelectId,
    },
    { skip: !company }
  );

  const {
    refetch: refetchSchedule,
    data: getData,
    isError: getError,
    isLoading: getLoading,
    error: getErrorData,
  } = useGetScheduleQuery(memoizedArgs);

  const [
    createSchedule,
    {
      isLoading: createLoading,
      isError: createError,
      isSuccess: createSuccess,
      reset: createReset,
      error: createErrorData,
    },
  ] = useCreateScheduleMutation();

  const [
    updateSchedule,
    {
      isLoading: updateLoading,
      isError: updateError,
      isSuccess: updateSuccess,
      reset: updateReset,
    },
  ] = useUpdateScheduleMutation();

  // define submit functions here and pass them to the form
  const handlePostSubmit = (createfeedData) => {
    createSchedule({ data: createfeedData })
      .then(() => {
        refetchSchedule();
      })
      .catch((error) => {
        console.error('Error:', error, error);
      });
  };

  const handlePatchSubmit = (data) =>
    updateSchedule({
      companyId: company.id,
      projectId: projectSelectId,
      data,
    }).unwrap();

  const { selectOnly: repeatPersistVal, secondSelect: startTimePersistVal } =
    useSelector((persistState) => persistState.scheduleFormRedux.selectOnly);

  return (
    <FetchingDisplay
      // if error code is 403 the project has not been scheduled yet
      // the form needs to send a post request on submit in this case
      isError={
        (getError && getErrorData && getErrorData?.status !== 403) || isError
      }
      isLoading={getLoading || isLoading}
      SuccessElement={
        <ErrorBoundary fallback={<PageErrors />}>
          {!projectDetails?.active ? (
            <PageErrors
              message='Activate your project to schedule it'
              hideText
              customErrorArtwork={<Avocado500 />}
              customButton={
                <ActivateProjectButton
                  projectData={{ ...projectDetails, active: true }}
                  companyId={company?.id}
                  user={user}
                />
              }
            />
          ) : (
            <Container maxWidth='xxl'>
              <TitleCard title='Schedule Settings'>
                <ToggleButtonSettings
                  toggled={toggled}
                  setToggled={setToggled}
                />
              </TitleCard>
              <StyledPaper>
                <ScheduleForm
                  // if the get req throws a 403 error, i.e. project has not been scheduled yet, send persisted values as the form will
                  // not have any data to be autofilled
                  // send the recieved data if it is present
                  data={
                    // project has not been scheduled yet
                    getError
                      ? {
                          repeat: repeatPersistVal || '24',
                          start_time: startTimePersistVal || '00:00-00:15',
                        }
                      : getData
                  }
                  handleSubmit={
                    !getError && getData?.id
                      ? handlePatchSubmit
                      : handlePostSubmit
                  }
                />
              </StyledPaper>
            </Container>
          )}
          <DataSendingModalBaseRTK
            open={
              createLoading ||
              createError ||
              createSuccess ||
              updateLoading ||
              updateError ||
              updateSuccess
            }
            handleCloseModal={() => {
              if (!createLoading && !updateLoading) {
                createReset();
                updateReset();
              }
            }}
            isLoading={createLoading || updateLoading}
            isError={createError || updateError}
            isSuccess={createSuccess || updateSuccess}
            successMessage={getError ? 'Schedule Created' : 'Schedule Updated'}
            errorMessage={
              getError
                ? createErrorData?.data?.errors
                : createErrorData?.data?.errors
            }
            reset={() => {
              createReset();
              updateReset();
            }}
          >
            <Box sx={{ height: '200px' }} />
          </DataSendingModalBaseRTK>
        </ErrorBoundary>
      }
      LoadingElement={<GlobalLoadingComponent />}
      ErrorElement={<PageErrors isServerError code={getErrorData?.status} />}
    />
  );
};

export { Schedule };
