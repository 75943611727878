import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// MUI imports
import { Button, Stack, Typography, Grid, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
// Internal component imports
import { FeedListCard } from './FeedListCard';
import { clearInputValue } from '../../Redux/FeedSummary/slice';
import { UserInfoContext } from '../../../../context/UserInfoContext';

// Main function component
const FeedsSummaryTable = ({ data, reloadFunction }) => {
  const navigate = useNavigate();
  const { user } = useContext(UserInfoContext);
  const handleFeedSettings = () => {
    navigate('../feeds/feed-settings');
  };

  const handleProjectSetting = () => {
    navigate('../settings');
  };

  const dispatch = useDispatch();
  const handleClearClick = () => {
    dispatch(clearInputValue());
  };

  return (
    <>
      <Grid container spacing={3}>
        {(user?.role !== 'COLLABORATOR' && user?.role !== 'SUB_COLLABORATOR') && (
          <Grid item lg={4} xs={12}>
            <Box
              sx={{
                border: '1px solid #dedede',
                textAlign: 'center',
                borderRadius: '8px',
                minHeight: '155px',
                '&:hover': { border: '1px solid #0096ff' },
              }}
              onClick={() => {
                handleFeedSettings();
                handleClearClick();
              }}
            >
              <Button sx={{ width: '100%', height: '155px' }}>
                <Stack
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                >
                  <AddIcon
                    sx={{
                      padding: '10px',
                      color: '#ffffff !important',
                      backgroundColor: '#0096ff',
                      fontSize: '45px !important',
                      borderRadius: '50%',
                    }}
                  />
                  <Typography sx={{ color: '#323C46', marginTop: '10px' }} variant='bodyMedium'>
                    Add new feed
                  </Typography>
                </Stack>
              </Button>
            </Box>
          </Grid>
        )}
        {data && data.length > 0 && [...data]?.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })).map((fData) => (
          <FeedListCard
            key={fData.id}
            channel={fData.channel}
            name={fData.name}
            id={fData.id}
            feedId={fData.id}
            active={fData.active}
            reloadFunction={reloadFunction}
          />
        ))}
      </Grid>

      <Stack
        direction='row'
        justifyContent='center'
        alignItems='center'
        sx={{ marginTop: '40px', marginBottom: '20px' }}
      >
        <Button
          sx={{
            width: '200px',
            margin: '5px',
          }}
          variant='contained'
          onClick={handleProjectSetting}
        >
          Go to Settings
        </Button>
      </Stack>
    </>
  );
};
export { FeedsSummaryTable };
