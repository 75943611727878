import React, { useEffect, useContext, useState } from 'react';
// third party imports
import {
  Stack,
  Button,
  Typography,
  Backdrop,
  CircularProgress,
  Box,
  FormHelperText,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// internal import
import { object, string } from 'yup';
import { DynamicSelectBox } from '../GlobalComponents/DynamicSelectBox';
import { DynamicTextField } from '../GlobalComponents/DynamicTextField';
import { StatusToggle } from '../GlobalComponents/StatusToggle';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import {
  useDeleteProjectMutation,
  useEditProjectMutation,
} from '../../../../api/RTK/services/feedProject';
import { SubmitButtonWithStatusModal } from '../../../../components/SubmitButtonWithStatusModal';
import { DataSendingModalBaseRTK } from '../../../../components/DataSendingModalBaseRTK';
import { HeadingWithUnderline } from '../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { TextInput } from '../../../../components/Inputs/TextInput';
import { setProjectName } from '../../Redux/AllProjects/slice';
import { StyledBox } from '../StyledComponents/StyledImportFileForm';
import { SuccessSnackbar } from '../../../../widgets/SnackbarNew/SuccessSnackbar/SuccessSnackbar';

const validationSchema = object({
  delete: string().required('Type "delete"').oneOf(['delete'], 'Type "delete"'),
});

const DeleteProject = ({ companyId, projectId, user }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [deleteProject, { isLoading, isSuccess, isError, reset }] =
    useDeleteProjectMutation();

  const handleDeleteProject = () => {
    deleteProject({
      companyId,
      projectId,
    })
      .unwrap()
      .then(() => {
        setTimeout(() => {
          navigate('..');
        }, 500);
      });
  };

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 6 }} open={isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Button
        sx={{
          margin: '0px 10px',
          height: '42px',
          '&:hover': {
            svg: { color: '#e13f2a !important' },
            color: '#e13f2a',
            border: '1px solid #e13f2a',
          },
        }}
        variant='pillOutlined'
        onClick={openModal}
        disabled={
          user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR'
        }
      >
        <DeleteOutlineIcon
          sx={{ color: '#888888', fontSize: '18px', marginRight: '4px' }}
        />
        Delete Project
      </Button>
      <DataSendingModalBaseRTK
        open={open}
        handleCloseModal={closeModal}
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        errorMessage='Unable to delete project'
        successMessage='Project deleted successfully'
        reset={reset}
      >
        <HeadingWithUnderline heading='Delete this project ?' />
        <Box sx={{ mt: 3 }}>
          <Formik
            initialValues={{ delete: '' }}
            validationSchema={validationSchema}
            onSubmit={handleDeleteProject}
          >
            {() => (
              <Form>
                <TextInput
                  name='delete'
                  label={"Type 'delete' to confirm"}
                  placeholder='delete'
                  id='delete-confirm'
                  hideLabel={false}
                />
                <Box sx={{ display: 'flex', gap: 2, mt: 4 }}>
                  <Button
                    variant='outlined'
                    type='button'
                    fullWidth
                    onClick={closeModal}
                  >
                    Cancel
                  </Button>
                  <Button variant='contained' type='submit' fullWidth>
                    Delete
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </DataSendingModalBaseRTK>
    </>
  );
};

// Main function for component
const ProjectSettingsForm = ({ data, fields, defaultField }) => {
  const [alignment, setAlignment] = useState('');
  const navigate = useNavigate();
  const { company, user } = useContext(UserInfoContext);
  const dispatch = useDispatch();
  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );
  const [successMessage, setSuccessMessage] = useState('');

  const [
    editProject,
    {
      isLoading: editLoading,
      isSuccess: editSuccess,
      isError: editError,
      reset: editReset,
      isUninitialized: editUninitialized,
    },
  ] = useEditProjectMutation();

  useEffect(() => {
    if (data?.active === true) {
      setAlignment('Active');
    } else {
      setAlignment('Inactive');
    }
  }, [data]);
  // patch request
  const handlePatchSubmit = (createPatchData) => {
    dispatch({ type: 'SENDING' });
    editProject({ companyId: company?.id, data: createPatchData })
      .then((response) => {
        if (response.data) {
          if (response.data.active) {
            setSuccessMessage('Project Activated successfully');
          } else {
            setSuccessMessage('Project Inactivated successfully');
          }
          dispatch(setProjectName(response.data.name));
          dispatch({ type: 'SENDSUCCESS', data: response.data });
          setTimeout(() => {
            dispatch({ type: 'PRESEND' });
            if (response.data.active) {
              navigate('../settings/schedule');
            }
          }, 1000);
        }
      })
      .catch((error) => {
        dispatch({ type: 'SENDERROR', error: error.data });
      });
  };

  const handlePatchStatusToggle = (values) => {
    setAlignment((prevAlignment) => {
      const newAlignment = prevAlignment === 'Active' ? 'Inactive' : 'Active';

      handlePatchSubmit({
        feedproject_id: projectSelectId,
        company_id: company?.id,
        name: values.projectName,
        unique_field: values.selectOnly,
        active: newAlignment === 'Active',
      });

      return newAlignment;
    });
  };

  return (
    <>
      <Formik
        initialValues={{
          projectName: data?.name || '',
          selectOnly: data?.unique_field || defaultField.value || '--',
        }}
        enableReinitialize
        onSubmit={(values) => {
          if (
            user?.role !== 'COLLABORATOR' &&
            user?.role !== 'SUB_COLLABORATOR'
          ) {
            handlePatchSubmit({
              feedproject_id: projectSelectId,
              company_id: company?.id,
              name: values.projectName,
              unique_field: values.selectOnly,
              active: alignment === 'Active',
            });
          }
          navigate('../settings/schedule');
        }}
      >
        {({ values, handleBlur, handleChange, errors, touched }) => (
          <Form>
            <Stack
              alignItems='center'
              direction={{ xs: 'column', lg: 'row' }}
              flexWrap='wrap'
            >
              <StyledBox className='projectInput'>
                <DynamicTextField
                  textTitle='Project name'
                  name='projectName'
                  value={values.projectName}
                  inputPlaceholder='Example Name'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={
                    user?.role === 'COLLABORATOR' ||
                    user?.role === 'SUB_COLLABORATOR'
                  }
                />
                <div className='errorMsg'>
                  {errors.projectName && touched.projectName ? (
                    <p className='form-error'>{errors.projectName}</p>
                  ) : null}
                </div>
              </StyledBox>
              <StyledBox
                className='projectSelect'
                sx={{ position: 'relative' }}
              >
                <DynamicSelectBox
                  name='selectOnly'
                  value={values.selectOnly}
                  inputTitle='Unique ID per item'
                  selectedItem={fields}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  disabled={
                    user?.role === 'COLLABORATOR' ||
                    user?.role === 'SUB_COLLABORATOR'
                  }
                />
                <FormHelperText sx={{ position: 'absolute', top: '64px' }}>
                  <Stack
                    direction='row'
                    alignItems='flex-start'
                    sx={{
                      margin: '25px auto',
                      color: '#888888',
                    }}
                  >
                    <InfoOutlinedIcon
                      size='small'
                      sx={{
                        fontSize: '20px',
                        marginTop: '2px',
                        marginRight: '5px',
                      }}
                    />
                    <Typography>
                      We will use this ID to identify your items across multiple
                      channels
                    </Typography>
                  </Stack>
                </FormHelperText>
              </StyledBox>
            </Stack>

            <Stack direction='column' sx={{ marginTop: '30px' }}>
              <Typography variant='bodyMedium' sx={{ marginBottom: '4px' }}>
                Status
              </Typography>
              <Stack direction='row' alignItems='center'>
                <StatusToggle
                  alignment={alignment}
                  setAlignment={setAlignment}
                  toggleClass='statusToggle'
                  className='statusToggleGroup'
                  onClick={handlePatchStatusToggle}
                />
                <DeleteProject
                  companyId={company.id}
                  projectId={projectSelectId}
                  user={user}
                />
              </Stack>
            </Stack>

            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              marginTop='20px'
            >
              <SubmitButtonWithStatusModal
                buttonSX={{
                  width: '200px',
                  margin: '25px 5px',
                }}
                variant='contained'
                buttonText={
                  user?.role !== 'COLLABORATOR' &&
                  user?.role !== 'SUB_COLLABORATOR'
                    ? 'Save'
                    : 'Next'
                }
                isLoading={editLoading}
                isSuccess={editSuccess}
                isError={editError}
                isUninitialized={editUninitialized}
                reset={editReset}
                errorTextElement={
                  <>
                    <Typography
                      variant='bodySemiBold'
                      sx={{ fontSize: '1.125rem' }}
                    >
                      Couldn&apos;t Save
                    </Typography>
                    <br />
                    <Typography
                      variant='bodySemiBold'
                      sx={{ color: 'error.main', fontSize: '1.125rem' }}
                    >
                      Please try again
                    </Typography>
                  </>
                }
                successTextElement={
                  user?.role !== 'COLLABORATOR' &&
                  user?.role !== 'SUB_COLLABORATOR' && (
                    <Stack direction='row' gap={1}>
                      <Typography
                        variant='bodySemiBold'
                        sx={{ fontSize: '1.125rem' }}
                      >
                        Saved
                      </Typography>
                      <Typography
                        variant='bodySemiBold'
                        sx={{ color: 'primary.main', fontSize: '1.125rem' }}
                      >
                        Successfully
                      </Typography>
                    </Stack>
                  )
                }
              >
                Save
              </SubmitButtonWithStatusModal>
            </Stack>
          </Form>
        )}
      </Formik>
      {editSuccess && (
        <SuccessSnackbar message={successMessage} setMsg={setSuccessMessage} />
      )}
    </>
  );
};

export { ProjectSettingsForm };
