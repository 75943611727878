import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';

// MUI imports
import {
  Button,
  Paper,
  Box,
  Typography,
  //   CircularProgress,
  Stack,
  Tooltip,
  Skeleton,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

// internal component imports
import { useItemsOverviewQuery } from '../../../../api/RTK/services/feedItems';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import { FetchingDisplay } from '../../../../components/FetchingDisplay';
import { PageErrors } from '../../../../components/PageErrors';

// OverviewDetails will open onClick of OverviewBox component
const OverviewDetails = ({ isBoxVisible }) => {
  const { company } = useContext(UserInfoContext);
  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );
  const feedId = useSelector((state) => state.feedSummaryRedux.feedId);

  const { data, isLoading, isError, error } = useItemsOverviewQuery({
    companyId: company?.id,
    projectId: projectSelectId,
    feedId,
  });

  const getDynamicTooltipContent = (title) => {
    if (title === 'Total Items') {
      return 'Total imported items - the number of items imported';
    }
    if (title === 'In Stock Items') {
      return 'Products that are in stock';
    }
    if (title === 'Out Of Stock Items') {
      return 'Products that are out of stock';
    }
    if (title === 'Filtered Out Items') {
      return 'Removed products using "Filters"';
    }
    if (title === 'Items Remaining') {
      return 'The number of items in the feed after applying all the "Actions" & "Filters".';
    }
    return 'Overview Tooltip';
  };

  return (
    <FetchingDisplay
      isError={isError}
      isLoading={isLoading}
      ErrorElement={
        <Paper
          elevation={1}
          sx={{
            display: isBoxVisible ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 2,
            padding: '16px',
            backgroundColor: '#ffffff',
            margin: '4px 24px 24px 24px',
            borderRadius: '8px',
          }}
        >
          {error ? (
            <Typography
              variant='bodySemiBold'
              sx={{ color: 'rgb(225, 63, 42)', margin: 'auto' }}
            >
              Error {error?.status}: {error?.data?.errors}
            </Typography>
          ) : (
            <Typography
              variant='bodySemiBold'
              sx={{ color: 'rgb(225, 63, 42)', margin: 'auto' }}
            >
              Something went wrong..
            </Typography>
          )}
        </Paper>
      }
      LoadingElement={
        !data && isLoading ? (
          <Paper
            elevation={1}
            sx={{
              display: isBoxVisible ? 'flex' : 'none',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 2,
              padding: '8px 12px',
              backgroundColor: '#ffffff',
              margin: '4px 24px 24px 24px',
              borderRadius: '8px',
            }}
          >
            <Skeleton variant='text' height={60} sx={{ width: '100%' }} />
          </Paper>
        ) : null
      }
      SuccessElement={
        data ? (
          <ErrorBoundary fallback={<PageErrors />}>
            <Paper
              elevation={1}
              sx={{
                display: isBoxVisible ? 'block' : 'none',
                zIndex: 2,
                backgroundColor: '#ffffff',
                margin: '4px 24px 24px 24px',
                borderRadius: '8px',
                padding: '16px',
              }}
            >
              <Stack direction={{ lg: 'row', sm: 'column' }} spacing={2}>
                {data?.map((overview) => (
                  <Box
                    key={overview.title}
                    sx={{
                      border: '1px solid #dedede',
                      padding: '8px',
                      borderRadius: '4px',
                      flexBasis: '20%',
                    }}
                  >
                    <Stack direction='row' justifyContent='space-between'>
                      <Typography variant='smallMedium'>
                        {overview.title}
                      </Typography>
                      <Tooltip
                        title={getDynamicTooltipContent(overview.title)}
                        arrow
                        PopperProps={{
                          sx: {
                            '&.MuiTooltip-popper ': {
                              zIndex: '999999999',
                            },
                          },
                        }}
                      >
                        <HelpOutlineOutlinedIcon
                          fontSize='small'
                          color='disabled'
                        />
                      </Tooltip>
                    </Stack>
                    <Typography
                      variant='body1'
                      sx={{
                        color: '#0096ff',
                        fontWeight: '600',
                        marginTop: '4px',
                      }}
                    >
                      {overview.count}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Paper>
          </ErrorBoundary>
        ) : null
      }
    />
  );
};

const OverviewBox = ({ isBoxVisible, setIsBoxVisible }) => {
  const [isOpen, setIsOpen] = useState(true);
  useEffect(() => {
    setIsBoxVisible(true);
  }, [setIsBoxVisible]);

  const handleToggleBox = () => {
    setIsBoxVisible(!isBoxVisible);
    setIsOpen(!isOpen);
  };

  return (
    <Paper
      elevation={1}
      sx={{ marginRight: '24px', marginTop: '14px', borderRadius: '8px' }}
    >
      <Button
        variant='outlined'
        disableRipple
        onClick={handleToggleBox}
        endIcon={
          <KeyboardArrowDownIcon
            sx={{
              color: '#0096ff !important',
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        }
        sx={{
          backgroundColor: '#ffffff',
          border: 'none',
          color: '#323c46',
          borderRadius: '8px',
          '&:hover': {
            backgroundColor: '#ffffff',
            border: 'none',
          },
        }}
      >
        Items Overview
      </Button>
    </Paper>
  );
};

export { OverviewBox, OverviewDetails };
