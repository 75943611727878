import React, { useState } from 'react';

// MUI imports
import { Container, Stack, Button } from '@mui/material';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Internal imports
import { ErrorBoundary } from 'react-error-boundary';
import { TitleCard } from '../Components/GlobalComponents/TitlePaper';
import { EnhanceMainListTable } from '../Components/OtherComponents/EnhanceMainListTable';
import { FilterOutModal } from '../Components/OtherComponents/FilterOutModal';
import { ToggleButtonFeeds } from '../Components/OtherComponents/ToggleButtonFeeds';
import { ActionMainModal } from '../Components/OtherComponents/ActionMainModal';
import { PageErrors } from '../../../components/PageErrors';
import { SearchWithButton } from '../Components/GlobalComponents/SearchWithButton';
import { AddColumnModal } from '../Components/OtherComponents/AddColumnModal';
// Styled imports
import { StyledPaper } from './StyledMainBox';

// main function component
const EnhancePage = () => {
  const [toggled, setToggled] = useState('Enhance');
  const [count, setCount] = useState(1);
  const [disable, setDisable] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [openCustomCol, setopenCustomCol] = useState(false);
  const handleSearchButtonClick = (value) => {
    if (!isDataLoading) {
      setSearchTerm(value);
    }
  };

  const reloadList = () => {
    setCount(count + 1);
  };

  return (
    <>
      <ErrorBoundary fallback={<PageErrors />}>
        <Container maxWidth='xxl'>
          <TitleCard title='Enhance'>
            <Stack
              direction='row'
              spacing={2}
              sx={{
                width: '100%',
                '@media (max-width: 1100px)': {
                  flexDirection: 'column',
                },
                '@media (max-width: 769px)': {
                  flexDirection: 'column',
                },
              }}
            >
              <Stack
                direction='row'
                alignItems='center'
                spacing={2}
                sx={{
                  width: '100%',
                  '@media (max-width: 1100px)': {
                    marginBottom: '16px !important',
                  },
                }}
              >
                <SearchWithButton
                  placeholder='Search'
                  showEndAdornment
                  onClick={handleSearchButtonClick}

                />

                <FilterOutModal modalTitle='Filters' reloadList={reloadList} />
                <ActionMainModal modalTitle='Actions' reloadList={reloadList} />
              </Stack>
              <ToggleButtonFeeds toggled={toggled} setToggled={setToggled} />

              <Button
                variant='pillOutlined'
                disableRipple
                sx={{
                  height: '44px',
                  minWidth: '250px',
                  '&:hover': { svg: { color: '#0096ff !important' } },
                  svg: { paddingRight: '5px', fontSize: '30px' },
                  '@media (max-width: 1100px)': {
                    marginTop: '15px !important',
                  },
                }}
                onClick={() => setopenCustomCol(true)}
                disabled={disable}
              >
                <EditNoteOutlinedIcon />
                Customise Columns
              </Button>
            </Stack>
          </TitleCard>
          <StyledPaper sx={{ padding: '0px' }}>
            <EnhanceMainListTable
              search={searchTerm}
              onDataLoading={setIsDataLoading}
              setDisable={setDisable}
            />
          </StyledPaper>
        </Container>
      </ErrorBoundary>
      <AddColumnModal
        openModel={openCustomCol}
        setModelOpen={setopenCustomCol}
        modalTitle='Add or Delete Columns'
        setftype=''
      />
    </>
  );
};

export { EnhancePage };
