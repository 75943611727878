/*
isLoading, isError: Boolean
LoadingElement, ErrorElement, SuccessElement: Component
*/
const FetchingDisplay = ({
  isLoading,
  isError,
  LoadingElement,
  ErrorElement,
  SuccessElement,
}) => {
  if (isError) {
    return ErrorElement;
  }
  if (isLoading) {
    return LoadingElement;
  }

  return SuccessElement;
};

export { FetchingDisplay };
