/* eslint-disable no-use-before-define */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useMemo, useState, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/order
import {
  setEnhancePageNumber,
  setPreviewPageNumber,
  clearEnhancepageNumber,
  clearPreviewpageNumber,
} from '../../pages/FeedManagement/Redux/PaginationVales/slice';
// eslint-disable-next-line import/order
import {
  updateColumnTobeRenderd,
  updateColumnInAffectedItems,
  updateColumnInEnhance,
  updateColumnInPreview,
  updateColumn,
  updateColumnTobeRenderdInAffectedItems,
  updateColumnTobeRenderdInEnhance,
  updateColumnTobeRenderdInPreview,
} from '../../pages/FeedManagement/Redux/CustomizableHeadcells/All-Items/slice';
// import { addAllItemsColumn } from '../../pages/FeedManagement/Redux/CustomizableHeadcells/All-Items/slice';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Stack,
} from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { visuallyHidden } from '@mui/utils';
import { Link, useLocation } from 'react-router-dom';
import { FetchingDisplay } from '../FetchingDisplay';
import { GlobalLoadingComponent } from '../GlobalLoadingComponent';
import { GlobalErrorComponent } from '../GlobalErrorComponent';

const monthOrder = {
  january: 0,
  february: 1,
  march: 2,
  april: 3,
  may: 4,
  june: 5,
  july: 6,
  august: 7,
  september: 8,
  october: 9,
  november: 10,
  december: 11,
};

const compareNumbers = (num1, num2) => num1 - num2;

const extractNumericValue = (value) =>
  typeof value === 'string' ? value?.replace(/[^\d]/g, '') : value;

function descendingComparator(a, b, orderBy) {
  const valueA = a[orderBy];
  const valueB = b[orderBy];
  const isFirstCharNumeric = /^[0-9]/.test(valueA);
  if (isFirstCharNumeric) {
    const [num1, num2] = [
      extractNumericValue(valueA),
      extractNumericValue(valueB),
    ];
    return compareNumbers(num1, num2);
  } else {
    if (valueB < valueA) {
      return -1;
    }
    if (valueB > valueA) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator, sortingOrder, orderBy) {
  if (orderBy.toLowerCase() === 'month') {
    return array.reduce((acc, el) => {
      acc[
        sortingOrder === 'desc'
          ? 11 - monthOrder[el.Month.toLowerCase()]
          : monthOrder[el.Month.toLowerCase()]
      ] = el;
      return acc;
    }, []);
  }

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = ({
  headCells,
  order,
  orderBy,
  onRequestSort,
  verticalBorders = false,
  setResizable,
  setMaxWidth,
}) => {
  const tableHeadRef = useRef(null);
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const [columnWidths, setColumnWidths] = useState(headCells.map(() => 50));
  const resizingColumnIndex = useRef(null);
  const cellRefs = useRef([]);
  const startX = useRef(null);
  const startWidth = useRef(null);
  const isResizing = useRef(false);
  const resizeThreshold = 5;
  useEffect(() => {
    const initialWidths = cellRefs.current.map(
      (cell) => cell.getBoundingClientRect().width
    );
    setColumnWidths(initialWidths);
  }, []);

  useEffect(() => {
    const initialWidths = cellRefs.current.map(
      (cell) => cell.getBoundingClientRect().width
    );
    setColumnWidths(initialWidths);
  }, []);
  // eslint-disable-next-line no-unused-vars
  const handleMouseDown = (event, index) => {
    // Directly measure the current width of the table cell
    const cell = event.target.closest('th');
    if (cell) {
      const cellRect = cell.getBoundingClientRect();
      startWidth.current = cellRect.width;
    } else {
      startWidth.current = columnWidths[index];
    }
    resizingColumnIndex.current = index;
    startX.current = event.clientX;
    isResizing.current = true;
    setResizable(index, true);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    event.preventDefault();
  };
  const handleMouseMove = (event) => {
    if (resizingColumnIndex.current !== null) {
      const deltaX = event.clientX - startX.current;
      if (!isResizing.current && Math.abs(deltaX) > resizeThreshold) {
        isResizing.current = true;
        setResizable(resizingColumnIndex.current, true);
      }
      if (isResizing.current) {
        const newWidth = startWidth.current + deltaX;
        setColumnWidths((prevWidths) => {
          const newWidths = [...prevWidths];
          newWidths[resizingColumnIndex.current] = Math.max(newWidth, 20);
          setMaxWidth(resizingColumnIndex.current, Math.max(newWidth, 20));
          return newWidths;
        });
      }
    }
  };
  const handleMouseUp = () => {
    if (resizingColumnIndex.current !== null) {
      setResizable(resizingColumnIndex.current, false);
      resizingColumnIndex.current = null;
    }
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
    startX.current = null;
    startWidth.current = null;
    isResizing.current = false;
  };
  return (
    <TableHead ref={tableHeadRef}>
      <TableRow className={verticalBorders ? '' : 'noVerticalBorders'}>
        {headCells.map((headCell, index) => (
          <TableCell
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            align={headCell?.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell?.id ? order : false}
            // eslint-disable-next-line no-return-assign
            ref={(el) => (cellRefs.current[index] = el)}
            sx={{
              minWidth: columnWidths[index]
                ? `${columnWidths[index]}px`
                : '50px',
              width: columnWidths[index] ? `${columnWidths[index]}px` : '50px',
              position: 'relative !important',
              border: '1px solid #DEDEDE',
              padding: '16px 0px 16px 16px !important',
              borderTop: '0px !important',

              transition: 'width 0.1s ease',
              '&:first-of-type': {
                borderLeft: '0px',
              },
            }}
          >
            {/* label exists and isImage is false */}
            {headCell?.label && !headCell?.isImage && (
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={orderBy === headCell?.id ? order : 'asc'}
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell?.label}
                {orderBy === headCell?.id ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
            {/* label exists and isImage is true */}
            {headCell?.label && headCell?.isImage && headCell?.label}
            <Box
              onMouseDown={(event) => handleMouseDown(event, index)}
              sx={{
                position: 'absolute',
                right: -5,
                // left: -5,
                top: 0,
                bottom: 0,
                width: '10px',
                cursor: 'col-resize',
                zIndex: 2,
              }}
            />
          </TableCell>
        ))}
        <TableCell
          sx={{
            flexGrow: 1,
            width: 'auto',
            position: 'relative !important',
            border: '1px solid #DEDEDE',
            padding: '16px 0px 16px 16px !important',
            borderTop: '0px !important',
          }}
        >
          <Box
            onMouseDown={(event) => handleMouseDown(event)}
            sx={{
              position: 'absolute',
              right: -5,
              // left: -5,
              top: 0,
              bottom: 0,
              width: '10px',
              cursor: 'col-resize',
              zIndex: 2,
            }}
          />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

// headcells is an array of objects with the following fields
/// //////////

// id: string. name of the field in 'rows' object
// numeric: boolean. The corresponding field will be included in the total on the footer if true.
// label: string. Label to be displayed.
// disablePadding: Boolean. Padding is reduced when true.
// formatFunction: Callback returning the desired component/string to be display for the value.
//                 The callback will recieve the current row object as an argument.
// totalFunction?: Callback returning the total of correspoding field. The callback will recieve all rows as an argument
/// /////////

// rowIdField: this is the field whose value is used when 'key' are required. Must be unique
// rowsPerPage: Integer.
// verticalBorder: Boolean. Toggles Vertical borders.
// defaultOrder: 'asc'/ 'desc'
// defaultOrderBy: String.
// fetcherHook: useQuery or useMutation type. RTK useQuery and useMutation hook to be called with args. This fetches the data shown in table.
// headCellGenerator: () => []. Returns array of headcells. Used only when headcells are not provided.
// args: object. This is spread and memoised before passing to fetcherFunction.

// eslint-disable-next-line arrow-body-style
const transformHeadCellsWithValues = (array) => {
  // Array to store format functions with identifiers
  const formatFunctions = [];

  // Create a new array without the 'formatFunction' property in each object
  const transformedArray = array.map((headCell) => {
    const { formatFunction, ...rest } = headCell;
    if (formatFunction) {
      formatFunctions.push({
        label: headCell.label,
        formatFunction,
      });
    }
    return rest;
  });
  return { transformedArray, formatFunctions };
};

// function to get the column values based on the location
const columnSelector = (location) => {
  if (location.pathname.includes('/feed-management/feeds/enhance')) {
    return useSelector(
      (state) => state.allItemCustomizedColumnSlice.enhaceColumn
    );
  } else if (location.pathname.includes('/feed-management/feeds/preview')) {
    return useSelector(
      (state) => state.allItemCustomizedColumnSlice.previewColumn
    );
  } else if (
    location.pathname.includes('/feed-management/feeds/affected-items')
  ) {
    return useSelector(
      (state) => state.allItemCustomizedColumnSlice.affectedItemsColumn
    );
  }
  return useSelector(
    (state) => state.allItemCustomizedColumnSlice.allItemsColumn
  );
};

// function to get the column values to be rendered based on the location
const columnTobeRenderdSelector = (location) => {
  if (location.pathname.includes('/feed-management/feeds/enhance')) {
    return useSelector(
      (state) => state.allItemCustomizedColumnSlice.columnTobeRenderdInEnhance
    );
  } else if (location.pathname.includes('/feed-management/feeds/preview')) {
    return useSelector(
      (state) => state.allItemCustomizedColumnSlice.columnTobeRenderdInPreview
    );
  } else if (
    location.pathname.includes('/feed-management/feeds/affected-items')
  ) {
    return useSelector(
      (state) =>
        state.allItemCustomizedColumnSlice.columnTobeRenderdInAffectedItems
    );
  }
  return useSelector(
    (state) => state.allItemCustomizedColumnSlice.columnTobeRenderd
  );
};

// state for desired column

const desiredColumnUpdater = (location) => {
  if (location.pathname.includes('/feed-management/feeds/enhance')) {
    return useSelector(
      (state) => state.allItemCustomizedColumnSlice.desiredColumnInEnhance
    );
  } else if (location.pathname.includes('/feed-management/feeds/preview')) {
    return useSelector(
      (state) => state.allItemCustomizedColumnSlice.desiredColumnInPreview
    );
  } else if (
    location.pathname.includes('/feed-management/feeds/affected-items')
  ) {
    return useSelector(
      (state) => state.allItemCustomizedColumnSlice.desiredColumnInAffectedItems
    );
  }
  return useSelector(
    (state) => state.allItemCustomizedColumnSlice.desiredColumn
  );
};

const PaginatedTable = ({
  headCells,
  // rowIdField,
  rowsPerPage = 10,
  verticalBorders = false,
  defaultOrder = 'asc',
  defaultOrderBy = 'none',
  linkRows = false,
  linkFormatter = () => 'xxx',
  linkStateFormatter = (row) => row,
  fetcherHook,
  styleBodyCell,
  headCellGenerator = () => [],
  args = {},
  showPagination = true,
  onDataLoading,
  setResizable,
  setMaxWidth,
  setDisable,
  // desiredKeys,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [formatFunctionsContainer, setFormatFunctionsContainer] = useState([]);
  const [
    filterFormettedFunctionContainer,
    setfilterFormettedFunctionContainer,
  ] = useState([]);
 
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const tableValues = columnSelector(location);
  let columnsTobeRender = columnTobeRenderdSelector(location);
  columnsTobeRender = columnsTobeRender.filter((el) => el);
  const desiredColumn = desiredColumnUpdater(location);
  const pageNumber = location.pathname.includes(
    '/feed-management/feeds/enhance'
  )
    ? useSelector((state) => state.PaginationValuesReducers.EnhacepageNumber)
    : useSelector((state) => state.PaginationValuesReducers.previewpageNumber);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    if (location.pathname.includes('/feed-management/feeds/enhance')) {
      dispatch(clearPreviewpageNumber());
    } else if (location.pathname.includes('/feed-management/feeds/preview')) {
      dispatch(clearEnhancepageNumber());
    } else {
      // Reset both page numbers if not on specific paths
      dispatch(clearEnhancepageNumber());
      dispatch(clearPreviewpageNumber());
    }
  }, [location.pathname, dispatch]);
  const handleChangePage = (event, newPage) => {
    if (location.pathname.includes('/feed-management/feeds/enhance')) {
      dispatch(setEnhancePageNumber(newPage));
    } else {
      dispatch(setPreviewPageNumber(newPage));
    }
  };

  const memoizedArgs = useMemo(
    () => ({
      ...args,
      pageNo: pageNumber + 1,
    }),
    [args, pageNumber]
  );
  const { data, isLoading, isError, isFetching } = fetcherHook(memoizedArgs);

  useEffect(() => {
    onDataLoading && onDataLoading(isLoading || isFetching);
  }, [isLoading, isFetching, onDataLoading]);

  const emptyRows =
    pageNumber > 0 && data?.count
      ? Math.max(0, rowsPerPage - data.data.length)
      : 0;
  useEffect(() => {
    setOrder(defaultOrder);
    setOrderBy(defaultOrderBy);
  }, [data?.data]);

  const headCellsToUse = headCells || headCellGenerator(data);
  useEffect(() => {
    if (!headCellsToUse) {
      setDisable(true);
    }
    if(true){
      setDisable(isLoading)
    }
  }, [headCellsToUse, data, setDisable]);

  useEffect(() => {
    if (data) {
      const { transformedArray, formatFunctions } =
        transformHeadCellsWithValues(headCellsToUse);
      dispatch(updateColumn(transformedArray));
      if (location.pathname.includes('/feed-management/feeds/enhance')) {
        dispatch(updateColumnInEnhance(transformedArray));
      } else if (location.pathname.includes('/feed-management/feeds/preview')) {
        dispatch(updateColumnInPreview(transformedArray));
      } else if (
        location.pathname.includes('/feed-management/feeds/affected-items')
      ) {
        dispatch(updateColumnInAffectedItems(transformedArray));
      } else {
        dispatch(updateColumn(transformedArray));
      }
      setFormatFunctionsContainer(formatFunctions);
    }
  }, [data, dispatch, isLoading, desiredColumn]);
  useEffect(() => {
    if (tableValues && desiredColumn.length > 0) {
      // Create a map to quickly access the index of each label in desiredColumn
      const labelIndexMap = {};
      desiredColumn.forEach((label, index) => {
        labelIndexMap[label.toLowerCase()] = index;
      });

      // Map tableValues to match the order in desiredColumn
      const orderedTableValues = desiredColumn.map((label) => {
        const foundItem = tableValues.find(
          (item) => item?.label?.toLowerCase() === label?.toLowerCase()
        );
        return foundItem || null;
      });
      // Update Redux state based on the pathname
      if (location.pathname.includes('/feed-management/feeds/affected-items')) {
        dispatch(updateColumnTobeRenderdInAffectedItems(orderedTableValues));
      } else if (location.pathname.includes('/feed-management/feeds/enhance')) {
        dispatch(updateColumnTobeRenderdInEnhance(orderedTableValues));
      } else if (location.pathname.includes('/feed-management/feeds/preview')) {
        dispatch(updateColumnTobeRenderdInPreview(orderedTableValues));
      } else {
        dispatch(updateColumnTobeRenderd(orderedTableValues));
      }

      // Find and set formatted functions container
      const filterFormattedColumns = desiredColumn.map((label) => {
        const foundItem = formatFunctionsContainer.find(
          (item) => item?.label?.toLowerCase() === label?.toLowerCase()
        );
        return foundItem || null;
      });
      setfilterFormettedFunctionContainer(filterFormattedColumns);
    }
  }, [
    tableValues,
    dispatch,
    isLoading,
    desiredColumn,
    formatFunctionsContainer,
    location.pathname,
  ]);

  return (
    <Stack height='100%'>
      <FetchingDisplay
        isLoading={isLoading || isFetching}
        isError={isError}
        LoadingElement={<GlobalLoadingComponent />}
        ErrorElement={<GlobalErrorComponent />}
        SuccessElement={
          data?.data && (
            <>
              <TableContainer>
                <Table
                  stickyHeader
                  aria-labelledby='tableTitle'
                  size='small'
                  sx={{ overflow: 'hidden' }}
                >
                  <EnhancedTableHead
                    headCells={columnsTobeRender}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={data.data.length}
                    verticalBorders={verticalBorders}
                    setResizable={setResizable}
                    setMaxWidth={setMaxWidth}
                  />
                  <TableBody>
                    {stableSort(
                      data?.data,
                      getComparator(order, orderBy),
                      order,
                      orderBy
                      // eslint-disable-next-line arrow-body-style
                    )?.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            textDecoration: 'none',
                          }}
                          className={verticalBorders ? '' : 'noVerticalBorders'}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...(linkRows
                            ? {
                                component: Link,
                                to: linkFormatter(row),
                                state: {
                                  ...linkStateFormatter(row),
                                  pageNumber,
                                },
                              }
                            : {})}
                        >
                          {columnsTobeRender?.map((headCell) => {
                            const formatFunctionObj =
                              filterFormettedFunctionContainer?.find(
                                (el) =>
                                  el?.label.toLowerCase() ===
                                    headCell?.label.toLowerCase() ||
                                  el?.Label?.toLowerCase() ===
                                    headCell?.label?.toLowerCase()
                              );

                            let formattedValue =
                              formatFunctionObj &&
                              formatFunctionObj.formatFunction(row);

                            formattedValue =
                              typeof formattedValue === 'object' &&
                              formattedValue?.type !== 'img'
                                ? formattedValue?.props?.children
                                : formattedValue;

                            return (
                              <TableCell
                                key={headCell?.id}
                                align={headCell?.alignRight ? 'right' : 'left'}
                                sx={{
                                  ...styleBodyCell,
                                  maxWidth: '200px',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {formattedValue}
                              </TableCell>
                            );
                          })}
                          <TableCell
                            sx={{
                              ...styleBodyCell,
                              maxWidth: '200px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          />
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 53 * emptyRows,
                        }}
                        className={verticalBorders ? '' : 'noVerticalBorders'}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {showPagination && (
                <TablePagination
                  rowsPerPageOptions={[rowsPerPage]}
                  component='div'
                  count={data.count}
                  rowsPerPage={rowsPerPage}
                  page={pageNumber}
                  onPageChange={handleChangePage}
                  defaultPage={1}
                />
              )}
            </>
          )
        }
      />
    </Stack>
  );
};
export { PaginatedTable };
